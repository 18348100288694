import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { FantasyPlayerModel, EditableFantasyPlayer, FantasyRoundModel, EditableFantasyRound, EditableFantasyRoundToSeries } from "./models";
import config from "../../config/config";
import SearchResult from "../shared/searchResult";
import SearchRequest from "../shared/searchRequest";

export interface FantasyRoundState {
  round: FantasyRoundModel;
  result: SearchResult<FantasyRoundModel[]>;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (round: EditableFantasyRound) => void;
  editState: RequestState;
  edit: (id: string, round: EditableFantasyRound) => void;
  searchState: RequestState;
  search: (request: SearchRequest) => void;
  deleteState: RequestState;
  delete: (id: string) => void;
  addSeriesState: RequestState;
  addSeries: (roundToSeries: EditableFantasyRoundToSeries) => void;
  removeSeriesState: RequestState;
  removeSeries: (id: string) => void;
  reset: () => void;
}

const useFantasyRoundStore = create<FantasyRoundState>()((set) => ({
  round: {} as FantasyRoundModel,
  result: {
    total: 0,
    result: []
  },
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<FantasyRoundModel>(
        `${config.apiUrl}/fantasy/rounds/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        round: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (round: EditableFantasyRound) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableFantasyRound>(
        `${config.apiUrl}/fantasy/rounds`,
        round,
        { withCredentials: true }
      );
      set(() => ({
        round: response.data as FantasyRoundModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, round: EditableFantasyRound) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableFantasyRound>(
        `${config.apiUrl}/fantasy/rounds/${id}`,
        round,
        { withCredentials: true }
      );
      set(() => ({
        round: response.data as FantasyRoundModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  searchState: RequestState.NotStarted,
  search: async (request: SearchRequest) => {
    set(() => ({ searchState: RequestState.Loading }));
    try {
      const response = await axios.post<SearchResult<FantasyRoundModel[]>>(
        `${config.apiUrl}/fantasy/rounds/search`,
        request,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        searchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ searchState: RequestState.Error }));
    }
  },
  deleteState: RequestState.NotStarted,
  delete: async (id: string) => {
    set(() => ({ deleteState: RequestState.Loading }));
    try {
      const response = await axios.delete<EditableFantasyRound>(
        `${config.apiUrl}/fantasy/rounds/${id}`,
        { withCredentials: true }
      );
      const deleted = response.data as FantasyRoundModel;
      set((state) => ({
        result: { ...state.result, result: state.result.result.filter(x => x.id !== deleted.id) },
        deleteState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ deleteState: RequestState.Error }));
    }
  },
  addSeriesState: RequestState.NotStarted,
  addSeries: async (roundToSeries: EditableFantasyRoundToSeries) => {
    set(() => ({ addSeriesState: RequestState.Loading }));
    try {
      const response = await axios.post<FantasyRoundModel>(
        `${config.apiUrl}/fantasy/rounds/series`,
        roundToSeries,
        { withCredentials: true }
      );
      set(() => ({
        round: response.data as FantasyRoundModel,
        addSeriesState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ addSeriesState: RequestState.Error }));
    }
  },
  removeSeriesState: RequestState.NotStarted,
  removeSeries: async (id: string) => {
    set(() => ({ removeSeriesState: RequestState.Loading }));
    try {
      const response = await axios.delete<FantasyRoundModel>(
        `${config.apiUrl}/fantasy/rounds/series/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        round: response.data as FantasyRoundModel,
        removeSeriesState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ removeSeriesState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted,
      searchState: RequestState.NotStarted,
      deleteState: RequestState.NotStarted
    }));
  }
}));

export default useFantasyRoundStore;
