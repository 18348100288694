import React from "react";

export const LogoComponent = () => {
    return (
        <div className="flex w-1/3">
            <img
                className="h-8 w-10 my-auto mx-2"
                src={"https://storage.uk.cloud.ovh.net/v1/AUTH_deb9ae76049440b8ab488e26bb502caf/raf/icons/frag_glow.svg"}
                alt="raf.isl.dev" />
            <h1 className="uppercase font-medium text-4xl mx-2">ADMIN</h1>
        </div>
    )
};

export default LogoComponent;