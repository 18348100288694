import React, { ReactNode, useEffect, useState } from "react";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import SearchRequest, { Filter } from "../../state/shared/searchRequest";
import Pagination from "../shared/pagination";
import SearchField from "../shared/SearchField";

export interface HeaderInformation {
  text: string;
  selector?: string;
}

export interface SearchableTableProps {
  total: number;
  headers: HeaderInformation[];
  search: (request: SearchRequest) => void;
  query?: string;
  isLoading?: boolean;
  isError?: boolean;
  filters?: Filter[];
  children: ReactNode[];
}

export const SearchableTable = (props: SearchableTableProps) => {
  const [request, setRequest] = useState<SearchRequest>({
    query: props.query ?? "",
    offset: 0,
    limit: 10,
    ordering: [],
    filters: props.filters ?? []
  });

  const handle = (selector: string) => {
    const ordering = request.ordering?.at(0);
    if (ordering && ordering.field === selector)
      setRequest({
        ...request,
        ordering: [{ field: selector, descending: !ordering.descending }],
      });
    else
      setRequest({
        ...request,
        ordering: [{ field: selector, descending: true }],
      });
  };

  const { search } = props;
  useEffect(() => {
    search(request);
  }, [search, request]);

  if (props.isLoading) {
    return (
      <>
        <span className="text-center text-white">Loading...</span>
      </>
    )
  }

  if (props.isError) {
    return (
      <>
        <span className="text-center text-red-300">Failed to load search</span>
      </>
    )
  }

  return (
    <>
      <div className="w-1/4">
        <SearchField
          query={request.query}
          setQuery={(query: string) => setRequest({ ...request, query: query })} />
      </div>
      <table className="w-full bg-black bg-opacity-20 text-base mb-2 text-gray-300 rounded-xl overflow-hidden">
        <thead className="text-base text-center uppercase bg-black bg-opacity-20">
          <tr>
            {props.headers.map((header, index) =>
              <th key={index} scope="col">
                {header.selector ?
                  <div
                    className="flex h-12 justify-center items-center hover:bg-black/80 hover:cursor-pointer"
                    onClick={() => handle(header.selector!)}>
                    <div className="flex items-center">
                      {header.text}
                      {request.ordering?.at(0)?.field === header.selector && (
                        <>
                          {request.ordering?.at(0)?.descending ? (
                            <CgChevronUp className="text-red-400 drop-shadow-[0px_0px_12px_rgba(240,240,240,1)]" />
                          ) : (
                            <CgChevronDown className="text-red-400 drop-shadow-[0px_0px_12px_rgba(240,240,240,1)]" />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  :
                  <div className="p-3">
                    {header.text}
                  </div>}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </table>

      <div className="col-span-4 w-full">
        <Pagination
          total={props.total}
          offset={request.offset}
          limit={request.limit}
          setOffset={(offset: number) =>
            setRequest({ ...request, offset: offset })
          }
          setLimit={(limit: number) =>
            setRequest({ ...request, limit: limit, offset: 0 })
          } />
      </div>
    </>
  );
}

export default SearchableTable;