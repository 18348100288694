import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { FantasyPlayerModel, EditableFantasyPlayer } from "./models";
import config from "../../config/config";
import SearchResult from "../shared/searchResult";
import SearchRequest from "../shared/searchRequest";

export interface FantasyPlayerState {
  player: FantasyPlayerModel;
  result: SearchResult<FantasyPlayerModel[]>;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (player: EditableFantasyPlayer) => void;
  editState: RequestState;
  edit: (id: string, player: EditableFantasyPlayer) => void;
  searchState: RequestState;
  search: (request: SearchRequest) => void;
  deleteState: RequestState;
  delete: (id: string) => void;
  reset: () => void;
}

const useFantasyPlayerStore = create<FantasyPlayerState>()((set) => ({
  player: {} as FantasyPlayerModel,
  result: {
    total: 0,
    result: []
  },
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<FantasyPlayerModel>(
        `${config.apiUrl}/fantasy/players/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        fantasy: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (player: EditableFantasyPlayer) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableFantasyPlayer>(
        `${config.apiUrl}/fantasy/players`,
        player,
        { withCredentials: true }
      );
      set(() => ({
        player: response.data as FantasyPlayerModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, fantasy: EditableFantasyPlayer) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableFantasyPlayer>(
        `${config.apiUrl}/fantasy/players/${id}`,
        fantasy,
        { withCredentials: true }
      );
      set(() => ({
        player: response.data as FantasyPlayerModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  searchState: RequestState.NotStarted,
  search: async (request: SearchRequest) => {
    set(() => ({ searchState: RequestState.Loading }));
    try {
      const response = await axios.post<SearchResult<FantasyPlayerModel[]>>(
        `${config.apiUrl}/fantasy/players/search`,
        request,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        searchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ searchState: RequestState.Error }));
    }
  },
  deleteState: RequestState.NotStarted,
  delete: async (id: string) => {
    set(() => ({ deleteState: RequestState.Loading }));
    try {
      const response = await axios.delete<EditableFantasyPlayer>(
        `${config.apiUrl}/fantasy/players/${id}`,
        { withCredentials: true }
      );
      const deleted = response.data as FantasyPlayerModel;
      set((state) => ({
        result: { ...state.result, result: state.result.result.filter(x => x.id !== deleted.id) },
        deleteState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ deleteState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted,
      searchState: RequestState.NotStarted,
      deleteState: RequestState.NotStarted
    }));
  }
}));

export default useFantasyPlayerStore;
