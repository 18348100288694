import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { TeamModel, EditableTeam, EditableSocialMapping, SocialMapping, EditableReferenceMapping, ReferenceModel } from "./teams";
import config from "../../config/config";

export interface TeamState {
  team: TeamModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (team: EditableTeam) => void;
  editState: RequestState;
  edit: (id: string, team: EditableTeam) => void;
  addSocialState: RequestState;
  addSocial: (id: string, socialId: string, mapping: EditableSocialMapping) => void;
  deleteSocialState: RequestState;
  deleteSocial: (id: string, mappingId: string) => void;
  addReferenceState: RequestState;
  addReference: (id: string, mapping: EditableReferenceMapping) => void;
  deleteReferenceState: RequestState;
  deleteReference: (id: string, mappingId: string) => void;
  reset: () => void;
}

const useTeamStore = create<TeamState>()((set) => ({
  team: {} as TeamModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<TeamModel>(
        `${config.apiUrl}/teams/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (team: EditableTeam) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableTeam>(
        `${config.apiUrl}/teams`,
        team,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data as TeamModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, team: EditableTeam) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableTeam>(
        `${config.apiUrl}/teams/${id}`,
        team,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data as TeamModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  addSocialState: RequestState.NotStarted,
  addSocial: async (id: string, socialId: string, mapping: EditableSocialMapping) => {
    set(() => ({ addSocialState: RequestState.Loading }));
    try {
      const response = await axios.post<TeamModel>(
        `${config.apiUrl}/teams/${id}/socials/${socialId}`,
        mapping,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        addSocialState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ addSocialState: RequestState.Error }));
    }
  },
  deleteSocialState: RequestState.NotStarted,
  deleteSocial: async (id: string, mappingId: string) => {
    set(() => ({ deleteSocialState: RequestState.Loading }));
    try {
      const response = await axios.delete<TeamModel>(
        `${config.apiUrl}/teams/${id}/socials/${mappingId}`,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        deleteSocialState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ deleteSocialState: RequestState.Error }));
    }
  },
  addReferenceState: RequestState.NotStarted,
  addReference: async (id: string, mapping: EditableReferenceMapping) => {
    set(() => ({ addReferenceState: RequestState.Loading }));
    try {
      const response = await axios.post<TeamModel>(
        `${config.apiUrl}/teams/${id}/references`,
        mapping,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        addReferenceState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ addReferenceState: RequestState.Error }));
    }
  },
  deleteReferenceState: RequestState.NotStarted,
  deleteReference: async (id: string, mappingId: string) => {
    set(() => ({ deleteReferenceState: RequestState.Loading }));
    try {
      const response = await axios.delete<TeamModel>(
        `${config.apiUrl}/teams/${id}/references/${mappingId}`,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        deleteReferenceState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ deleteReferenceState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted,
      addSocialState: RequestState.NotStarted,
      deleteSocialState: RequestState.NotStarted,
      addReferenceState: RequestState.NotStarted,
      deleteReferenceState: RequestState.NotStarted
    }));
  }
}));

export default useTeamStore;
