import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { EditableOrganizer, OrganizerModel } from "./organizers";
import config from "../../config/config";

export interface OrganizerState {
  organizer: OrganizerModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (organizer: EditableOrganizer) => void;
  editState: RequestState;
  edit: (id: string, organizer: EditableOrganizer) => void;
  reset: () => void;
}

const useOrganizerStore = create<OrganizerState>()((set) => ({
  organizer: {} as OrganizerModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<OrganizerModel>(
        `${config.apiUrl}/organizers/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        organizer: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (organizer: EditableOrganizer) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<OrganizerModel>(
        `${config.apiUrl}/organizers`,
        organizer,
        { withCredentials: true }
      );
      set(() => ({
        organizer: response.data as OrganizerModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, organizer: EditableOrganizer) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<OrganizerModel>(
        `${config.apiUrl}/organizers/${id}`,
        organizer,
        { withCredentials: true }
      );
      set(() => ({
        organizer: response.data as OrganizerModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useOrganizerStore;
