import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/maps/map";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditMap = () => {
    let { id } = useParams();

    const map = useStore((state) => state.map);
    const fetchState = useStore((state) => state.fetchState);
    const fetch = useStore((state) => state.fetch);
    const editState = useStore((state) => state.editState);
    const edit = useStore((state) => state.edit);
    const reset = useStore((state) => state.reset);

    useEffect(() => {
        if (id !== undefined) fetch(id);
    }, [fetch, id]);

    useEffect(() => {
        return () => reset();
    }, [reset]);

    const [name, setName] = useState<string>(map.name);
    const [image, setImage] = useState<string>(map.image);

    useEffect(() => {
        setName(map.name);
        setImage(map.image);
    }, [map]);

    return (
        <main className="flex flex-col w-full p-5">
            <Edit
                header="Edit Map"
                keys={["Id", "Name", "Image"]}
                save={() => edit(map.id, { name: name, image: image })}
                isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
                isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
                isSuccess={editState === RequestState.Complete}>
                <span>{map.id}</span>
                <input
                    className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
                    type="text"
                    value={name ?? ""}
                    onChange={(e) => setName(e.target.value)} />
                <input
                    className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
                    type="text"
                    value={image ?? ""}
                    onChange={(e) => setImage(e.target.value)} />
            </Edit>
        </main>
    );
};

export default EditMap;