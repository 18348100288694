import React, { ReactNode } from "react";
import { GiCheckMark } from "react-icons/gi";

export interface CreateProps {
    header: string;
    keys: string[];
    submit: () => void;
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    children: ReactNode[]
}

export const Create = (props: CreateProps) => {
    if (props.isLoading) {
        return (
            <>
                <span className="text-center text-white">Loading...</span>
            </>
        )
    }

    if (props.isError) {
        return (
            <>
                <span className="text-center text-red-300">Failed to load create</span>
            </>
        )
    }

    return (
        <section className="flex flex-col h-full p-5">
            <table className="bg-black bg-opacity-20 text-base mb-2 text-gray-300 rounded-xl overflow-hidden">
                <thead className="text-base uppercase bg-black bg-opacity-20">
                    <tr>
                        <th scope="col" className="py-3 px-6 text-start">{props.header}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="flex">
                        <td className="flex flex-col text-center w-48 font-bold justify-evenly">
                            {props.keys.map((key, index) =>
                                <span key={index} className={`p-3 bg-black ${index % 2 === 0 ? "bg-opacity-20" : "bg-opacity-5"}`}>
                                    {key}
                                </span>
                            )}
                        </td>
                        <td className="flex flex-col w-full font-light">
                            {props.children.map((child, index) =>
                                <div key={index} className={`p-3 bg-black ${index % 2 === 0 ? "bg-opacity-20" : "bg-opacity-5"}`}>
                                    {child}
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <button
                disabled={props.isLoading}
                onClick={() => props.submit()}
                className="flex p-3 rounded-md h-full bg-black bg-opacity-20 hover:bg-slate-800/70 hover:cursor-pointer">
                <GiCheckMark className="mx-3 text-white h-full" />
                <span className="text-xl text-white">{props.isLoading ? "Loading..." : "Submit"}</span>
            </button>
            {props.isSuccess && <span className="text-green-400 text-lg">Successfully created new data</span>}
            {props.isError && <span className="text-red-400 text-lg">Failed to create new data</span>}
        </section>
    );
};

export default Create;