import React, { useEffect } from "react";
import useStore from "../../state/vetoes/search";
import RequestState from "../../state/shared/requestState";
import { BsPencilFill } from "react-icons/bs";
import { NavLink, useParams } from "react-router-dom";
import SearchableTable from "../shared/searchableTable";
import SearchableTableResult from "../shared/searchableTableResult";
import { GiGamepad, GiHamburgerMenu } from "react-icons/gi";
import { FaPlus, FaTrash } from "react-icons/fa";
import { VetoType } from "../../state/vetoes/vetoes";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";

export const Vetoes = () => {
  const { query } = useParams();

  const result = useStore((state) => state.result);
  const request = useStore((state) => state.requestState);
  const search = useStore((state) => state.search);
  const deleteRequest = useStore((state) => state.deleteState);
  const deleteVeto = useStore((state) => state.delete);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <main className="flex flex-col h-full w-full p-5 gap-5">
      <NavLink to={"/veto/new"}>
        <div className="flex space-x-3 p-3 place-items-center rounded-md h-full w-1/12 hover:bg-slate-800/70">
          <FaPlus className="text-white" />
          <span className="text-lg text-white">New</span>
        </div>
      </NavLink>

      {deleteRequest === RequestState.Complete && <span className="text-green-400 text-lg">Successfully deleted the data</span>}
      {deleteRequest === RequestState.Error && <span className="text-red-400 text-lg">Failed to delete the data</span>}
      <SearchableTable
        headers={[
          { text: "Index" },
          { text: "Action" },
          { text: "Action On", selector: "name" },
          { text: "Team", selector: "teamname" },
          { text: "Series Id", selector: "seriesid" },
          { text: "Id" },
          { text: "Actions" }]}
        total={result.total}
        search={search}
        query={query}
        isLoading={request === RequestState.Loading}
        isError={request === RequestState.Error}>
        {result.result.map((r, index) =>
          <SearchableTableResult key={index}>
            <span>{r.index}</span>
            <span>{VetoType[r.action]}</span>
            <span>{r.actionOnName}</span>
            <span>{r.actionByTeamName}</span>
            <span>{r.seriesId}</span>
            <span>{r.id}</span>
            <div className="flex justify-center gap-4">
              <NavLink to={"/series/" + r.seriesId}>
                <RiCheckboxMultipleBlankFill />
              </NavLink>
              <NavLink to={`/veto/${r.id}`}>
                <GiHamburgerMenu />
              </NavLink>
              <NavLink to={`/veto/${r.id}/edit`}>
                <BsPencilFill />
              </NavLink>
              <button onClick={() => deleteVeto(r.id)}>
                <FaTrash />
              </button>
            </div>
          </SearchableTableResult>
        )}
      </SearchableTable>
    </main>
  );
};

export default Vetoes;