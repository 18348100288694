import React, { useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { GrDocumentStore } from "react-icons/gr";
import { useParams } from "react-router-dom";
import useStore from "../../state/matches/match";
import GameType from "../../state/shared/gameType";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Details from "../shared/details";

export const MatchDetails = () => {
  let { id } = useParams();

  const match = useStore((state) => state.match);
  const fetchState = useStore((state) => state.fetchMatchState);
  const fetch = useStore((state) => state.fetchMatch);

  const postDemoRequest = useStore((state) => state.postDemoState);
  const postDemo = useStore((state) => state.postDemo);

  const parseDemoState = useStore((state) => state.parseDemoState);
  const parseDemo = useStore((state) => state.parseDemo);

  const [demoUrl, setDemoUrl] = useState<string>("");

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Match Details"
        keys={["Id", "Reference", "Index", "State", "Game", "Series Id"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{match.id}</span>
        <span>{match.reference}</span>
        <span>{match.index}</span>
        <span>{State[match.state]}</span>
        <span>{GameType[match.game]}</span>
        <span>{match.seriesId}</span>
      </Details>

      <table className="bg-black bg-opacity-20 text-base mb-2 text-gray-300 rounded-xl overflow-hidden">
        <thead className="text-base uppercase bg-black bg-opacity-20">
          <tr>
            <th scope="col" className="flex py-3 px-6 justify-between">
              <span>Demo Urls</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-black bg-opacity-5">
            <td className="flex p-3 w-full font-light text-center justify-between">
              <span className="p-1">{match.demos?.url}</span>
              <div
                onClick={() => parseDemo(id!, match.demos.id)}
                className={`rounded-md bg-darkBg hover:bg-slate-800/70 hover:cursor-pointer
                          ${parseDemoState === RequestState.Error && "border border-red-300"}
                          ${parseDemoState === RequestState.Complete && "border border-green-300"}`}>
                <GrDocumentStore className="mx-3 h-full " />
              </div>
            </td>
          </tr>
          {postDemoRequest === RequestState.Error && (
            <tr>
              <td className="flex p-3 w-full font-light text-center">
                <span className="mx-auto text-medium text-red-300">Failed to save demo url</span>
              </td>
            </tr>)}
          <tr>
            <td className="flex p-3 w-full font-light text-center">
              <input
                className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
                type="text"
                value={demoUrl ?? ""}
                onChange={(e) => setDemoUrl(e.target.value)} />
              <div
                onClick={() => postDemo(id!, demoUrl)}
                className="rounded-md bg-darkBg hover:bg-slate-800/70 hover:cursor-pointer">
                <GiCheckMark className="mx-3 text-white h-full " />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </main>
  );
};

export default MatchDetails;