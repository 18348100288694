import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginComponent = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="flex flex-col w-96 h-32 rounded-xl justify-between items-center border border-lightSection p-3">
      <div className="flex justify-center">
        <img
          className="h-8 w-10 my-auto mx-2"
          src={"https://storage.uk.cloud.ovh.net/v1/AUTH_deb9ae76049440b8ab488e26bb502caf/raf/icons/frag_glow.svg"}
          alt="raf.isl.dev" />
        <h1 className="uppercase font-light text-white text-4xl mx-2">ADMIN</h1>
      </div>
      <div className="flex flex-col gap-2 w-2/5">
        <div
          onClick={() => loginWithRedirect()}
          className="flex rounded-md bg-darkSection w-full h-10 hover:bg-slate-800/70 hover:cursor-pointer">
          <span className="text-white my-auto mx-auto">Login with Auth0</span>
        </div>
      </div>
    </div>)
};

export default LoginComponent;