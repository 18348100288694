import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/vetoes/veto";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";
import { VetoType } from "../../state/vetoes/vetoes";

export const EditVeto = () => {
  let { id } = useParams();

  const veto = useStore((state) => state.veto);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [index, setIndex] = useState<number>(0);
  const [action, setAction] = useState<VetoType>(VetoType.Unknown);
  const [actionByTeamName, setActionByTeamName] = useState<string | null>(null);
  const [actionByTeamReference, setActionByTeamReference] = useState<string | null>(null);
  const [actionOnName, setActionOnName] = useState<string>("");
  const [seriesId, setSeriesId] = useState<string>("");

  useEffect(() => {
    setIndex(veto.index);
    setAction(veto.action);
    setActionByTeamName(veto.actionByTeamName);
    setActionByTeamReference(veto.actionByTeamReference);
    setActionOnName(veto.actionOnName);
    setSeriesId(veto.seriesId);
  }, [veto]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Veto"
        keys={["Id", "Index", "Action", "Action On", "Team Name", "Team Reference", "Series Id"]}
        save={() => edit(veto.id, { index: index, action: action, actionByTeamName: actionByTeamName, actionByTeamReference: actionByTeamReference, actionOnName: actionOnName, seriesId: seriesId })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{veto.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={index}
          onChange={(e) => setIndex(Number(e.target.value))} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={action}
          onChange={(e) => setAction(e.target.value as unknown as VetoType)}>
          <option value={VetoType.Unknown}>Unknown</option>
          <option value={VetoType.Ban}>Ban</option>
          <option value={VetoType.Pick}>Pick</option>
          <option value={VetoType.Leftover}>Leftover</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={actionOnName ?? ""}
          onChange={(e) => setActionOnName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={actionByTeamName ?? ""}
          onChange={(e) => setActionByTeamName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={actionByTeamReference ?? ""}
          onChange={(e) => setActionByTeamReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesId ?? ""}
          onChange={(e) => setSeriesId(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditVeto;