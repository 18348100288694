import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { MapModel, EditableMap } from "./maps";
import config from "../../config/config";

export interface MatchState {
  map: MapModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (map: EditableMap) => void;
  editState: RequestState;
  edit: (id: string, map: EditableMap) => void;
  reset: () => void;
}

const useMapStore = create<MatchState>()((set) => ({
  map: {} as MapModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<MapModel>(
        `${config.apiUrl}/maps/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        map: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (map: EditableMap) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableMap>(
        `${config.apiUrl}/maps`,
        map,
        { withCredentials: true }
      );
      set(() => ({
        map: response.data as MapModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, map: EditableMap) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableMap>(
        `${config.apiUrl}/maps/${id}`,
        map,
        { withCredentials: true }
      );
      set(() => ({
        map: response.data as MapModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useMapStore;
