import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/maps/map";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const MapDetails = () => {
  let { id } = useParams();

  const map = useStore((state) => state.map);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    // Runs after the first render() lifecycle
    if (id !== undefined) {
      fetch(id);
    }
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Map Details"
        keys={["Id", "Name", "Image"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{map.id}</span>
        <span>{map.name}</span>
        <div>
          <img alt="Map" src={map.image} />
          <a href={map.image}>
            {map.image}
          </a>
        </div>
      </Details>
    </main>
  );
};

export default MapDetails;