import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/series_teams/team";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditSeriesTeam = () => {
  let { id } = useParams();

  const team = useStore((state) => state.team);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [reference, setReference] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [tag, setTag] = useState<string | null>(null);
  const [seriesId, setSeriesId] = useState<string>("");

  useEffect(() => {
    setReference(team.reference);
    setName(team.name);
    setTag(team.tag);
    setSeriesId(team.seriesId);
  }, [team]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Team"
        keys={["Id", "Reference", "Name", "Tag", "Series Id"]}
        save={() => edit(team.id, { reference: reference, name: name, tag: tag, seriesId: seriesId })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{team.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tag ?? ""}
          onChange={(e) => setTag(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesId ?? ""}
          onChange={(e) => setSeriesId(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditSeriesTeam;