import React, { useEffect, useState } from "react";
import useStore from "../../state/organizers/organizer";
import RequestState from "../../state/shared/requestState";
import Create from "../shared/create";

export const CreateOrganizer = () => {
  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [name, setName] = useState<string>("");
  const [tag, setTag] = useState<string | null>(null);
  const [logo, setLogo] = useState<string>("");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Organizer"
        keys={["Name", "Tag", "Logo"]}
        submit={() => create({ name: name, tag: tag, logo: logo })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tag ?? ""}
          onChange={(e) => setTag(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={logo ?? ""}
          onChange={(e) => setLogo(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateOrganizer;