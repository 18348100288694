import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/series_teams/team";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const SeriesTeamDetails = () => {
  let { id } = useParams();

  const team = useStore((state) => state.team);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Team Details"
        keys={["Id", "Name", "Reference", "SeriesId", "Tag"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{team.id}</span>
        <span>{team.name}</span>
        <span>{team.reference}</span>
        <span>{team.seriesId}</span>
        <span>{team.tag}</span>
      </Details>
    </main>
  );
};

export default SeriesTeamDetails;