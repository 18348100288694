import React from "react";
import LoginComponent from "./loginComponent";

export const Login = () => {
  return (
    <div className="grid h-screen place-items-center">
      <LoginComponent />
    </div>
  );
};

export default Login;