import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { ResultModel, EditableResult } from "./results";
import config from "../../config/config";

export interface ResultState {
  result: ResultModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (result: EditableResult) => void;
  editState: RequestState;
  edit: (id: string, result: EditableResult) => void;
  reset: () => void;
}

const useResultStore = create<ResultState>()((set) => ({
  result: {} as ResultModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<ResultModel>(
        `${config.apiUrl}/results/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (result: EditableResult) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<ResultModel>(
        `${config.apiUrl}/results`,
        result,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, result: EditableResult) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<ResultModel>(
        `${config.apiUrl}/results/${id}`,
        result,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useResultStore;
