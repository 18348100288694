import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { Demo, EditableMatch, MatchModel } from "./matches";
import config from "../../config/config";

export interface MatchState {
  match: MatchModel;
  fetchMatchState: RequestState;
  fetchMatch: (id: string) => void;
  createState: RequestState;
  create: (match: EditableMatch) => void;
  editState: RequestState;
  edit: (id: string, match: EditableMatch) => void;
  postDemoState: RequestState;
  postDemo: (id: string, demoUrl: string) => void;
  parseDemoState: RequestState;
  parseDemo: (matchId: string, demoId: string) => void;
  reset: () => void;
}

const useMatchStore = create<MatchState>()((set) => ({
  match: {} as MatchModel,
  fetchMatchState: RequestState.NotStarted,
  fetchMatch: async (id: string) => {
    set(() => ({ fetchMatchState: RequestState.Loading }));
    try {
      const response = await axios.get<MatchModel>(
        `${config.apiUrl}/matches/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        match: response.data,
        fetchMatchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchMatchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (match: EditableMatch) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<MatchModel>(
        `${config.apiUrl}/matches`,
        match,
        { withCredentials: true }
      );
      set(() => ({
        match: response.data,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, match: EditableMatch) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<MatchModel>(
        `${config.apiUrl}/matches/${id}`,
        match,
        { withCredentials: true }
      );
      set(() => ({
        match: response.data,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  postDemoState: RequestState.NotStarted,
  postDemo: async (matchId: string, demoUrl: string) => {
    set(() => ({ postDemoState: RequestState.Loading }));
    try {
      const response = await axios.post<Demo>(
        `${config.apiUrl}/matches/${matchId}/demo`,
        { demoUrl: demoUrl },
        { withCredentials: true, timeout: 300000 }
      );
      set((state) => ({
        match: { ...state.match, demo: response.data },
        postDemoState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ postDemoState: RequestState.Error }));
    }
  },
  parseDemoState: RequestState.NotStarted,
  parseDemo: async (matchId: string, demoId: string) => {
    set(() => ({ parseDemoState: RequestState.Loading }));
    try {
      await axios.put(
        `${config.apiUrl}/matches/${matchId}/demo/${demoId}`,
        null,
        { withCredentials: true }
      );
      set(() => ({ parseDemoState: RequestState.Complete }));
    } catch (err) {
      set(() => ({ parseDemoState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted,
      postDemoState: RequestState.NotStarted,
      parseDemoState: RequestState.NotStarted
    }))
  },
}));

export default useMatchStore;
