import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/series/series";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Edit from "../shared/edit";

export const EditSeries = () => {
  let { id } = useParams();

  const series = useStore((state) => state.series);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [reference, setReference] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const [stageIndex, setStageIndex] = useState<number>(0);
  const [bestOf, setBestOf] = useState<number>(0);
  const [state, setState] = useState<State>(State.Unknown);
  const [startTime, setStartTime] = useState<string>("");
  const [bracketId, setBracketId] = useState<string>(id ?? "");
  const [name, setName] = useState<string | null>(null);

  useEffect(() => {
    setReference(series.reference);
    setIndex(series.index);
    setStageIndex(series.stageIndex);
    setBestOf(series.bestOf);
    setState(series.state);
    setStartTime(series.startTime);
    setBracketId(series.bracketId);
    setName(series.name);
  }, [series]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Series"
        keys={["Id", "Reference", "Index", "Stage Index", "Best Of", "State", "Start Time", "Bracket Id", "Name"]}
        save={() => edit(series.id, { reference: reference, index: index, stageIndex: stageIndex, bestOf: bestOf, state: state, startTime: startTime, bracketId: bracketId, name: name })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{series.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={index}
          onChange={(e) => setIndex(Number(e.target.value))} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={stageIndex}
          onChange={(e) => setStageIndex(Number(e.target.value))} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={bestOf}
          onChange={(e) => setBestOf(Number(e.target.value))} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={state}
          onChange={(e) => setState(e.target.value as unknown as State)}>
          <option value={State.Unknown}>Unknown</option>
          <option value={State.Ongoing}>Ongoing</option>
          <option value={State.Upcoming}>Upcoming</option>
          <option value={State.Completed}>Completed</option>
          <option value={State.Invalid}>Invalid</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={bracketId ?? ""}
          onChange={(e) => setBracketId(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditSeries;