import React from "react";
import { Auth0Provider } from '@auth0/auth0-react';
import Routing from "./Routing";
import config from "./config/config";

function App() {
  return (
    <Auth0Provider
      domain={config.authDomain}
      clientId={config.authClientId}
      authorizationParams={{
        audience: config.authAudience,
        scope: "admin",
        redirect_uri: window.location.origin
      }}>
      <Routing />
    </Auth0Provider>
  );
}

export default App;
