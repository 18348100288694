import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/streamers/streamer";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const StreamerDetails = () => {
  let { id } = useParams();

  const streamer = useStore((state) => state.streamer);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    // Runs after the first render() lifecycle
    if (id !== undefined) {
      fetch(id);
    }
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Streamer Details"
        keys={["Id", "Name", "Link", "Twitch Id", "Followers", "Image"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{streamer.id}</span>
        <span>{streamer.name}</span>
        <span>
          <a href={streamer.link}>
            {streamer.link}
          </a>
        </span>
        <span>{streamer.twitchId}</span>
        <span>{streamer.followers}</span>
        <span>
          <a href={streamer.image}>
            {streamer.image}
          </a>
        </span>
      </Details>
    </main>
  );
};

export default StreamerDetails;