import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/tournaments/tournament";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";
import GameType from "../../state/shared/gameType";

export const EditTournament = () => {
  let { id } = useParams();

  const tournament = useStore((state) => state.tournament);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [name, setName] = useState<string>(tournament.name);
  const [tag, setTag] = useState<string | null>(tournament.tag);
  const [logo, setLogo] = useState<string>(tournament.logo);
  const [game, setGame] = useState<GameType>(tournament.game);
  const [reference, setReference] = useState<string>(tournament.reference);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [externalLink, setExternalLink] = useState<string | null>(null);
  const [organizerId, setOrganizerId] = useState<string>(tournament.organizerId);

  useEffect(() => {
    setName(tournament.name);
    setTag(tournament.tag);
    setLogo(tournament.logo);
    setGame(tournament.game);
    setReference(tournament.reference);
    setStartTime(tournament.startTime);
    setExternalLink(tournament.externalLink);
    setOrganizerId(tournament.organizerId);
  }, [tournament]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Tournament"
        keys={["Id", "Name", "Tag", "Logo", "Game", "Reference", "Start Time", "External Link", "Organizer Id"]}
        save={() => edit(tournament.id, { name: name, tag: tag, logo: logo, game: game, reference: reference, startTime: startTime, externalLink: externalLink, organizerId: organizerId })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{tournament.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tag ?? ""}
          onChange={(e) => setTag(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={logo ?? ""}
          onChange={(e) => setLogo(e.target.value)} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={game}
          onChange={(e) => setGame(e.target.value as unknown as GameType)}>
          <option value={GameType.Varied}>Varied</option>
          <option value={GameType.CSGO}>CSGO</option>
          <option value={GameType.CS2}>CS2</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference ?? ""}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={startTime ?? ""}
          onChange={(e) => setStartTime(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={externalLink ?? ""}
          onChange={(e) => setExternalLink(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={organizerId ?? ""}
          onChange={(e) => setOrganizerId(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditTournament;