import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/organizers/organizer";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditOrganizer = () => {
  let { id } = useParams();

  const organizer = useStore((state) => state.organizer);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [name, setName] = useState<string>(organizer.name);
  const [tag, setTag] = useState<string | null>(organizer.tag);
  const [logo, setLogo] = useState<string>(organizer.logo);

  useEffect(() => {
    setName(organizer.name);
    setTag(organizer.tag);
    setLogo(organizer.logo);
  }, [organizer]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Organizer"
        keys={["Id", "Name", "Tag", "Logo"]}
        save={() => edit(organizer.id, { name: name, tag: tag, logo: logo })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{organizer.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tag ?? ""}
          onChange={(e) => setTag(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={logo ?? ""}
          onChange={(e) => setLogo(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditOrganizer;