import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/brackets/bracket";
import { BracketType } from "../../state/brackets/brackets";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Details from "../shared/details";

export const BracketDetails = () => {
  let { id } = useParams();

  const bracket = useStore((state) => state.bracket);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Bracket Details"
        keys={["Id", "Index", "Reference", "Type", "State", "Tournament Id", "Name"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{bracket.id}</span>
        <span>{bracket.index}</span>
        <span>{bracket.reference}</span>
        <span>{BracketType[bracket.type]}</span>
        <span>{State[bracket.state]}</span>
        <span>{bracket.tournamentId}</span>
        <span>{bracket.name}</span>
      </Details>
    </main>
  );
};

export default BracketDetails;