import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/bannables/bannable";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const BannableDetails = () => {
    let { id } = useParams();

    const bannable = useStore((state) => state.bannable);
    const fetchState = useStore((state) => state.fetchState);
    const fetch = useStore((state) => state.fetch);

    useEffect(() => {
        // Runs after the first render() lifecycle
        if (id !== undefined) {
            fetch(id);
        }
    }, [fetch, id]);

    return (
        <main className="flex flex-col h-full p-5">
            <Details
                header="Bannable Details"
                keys={["Id", "Name", "Image"]}
                isLoading={fetchState === RequestState.Loading}
                isError={id === undefined || fetchState === RequestState.Error}>
                <span>{bannable.id}</span>
                <span>{bannable.name}</span>
                <div>
                    <img alt="Bannable" src={bannable.image} />
                    <a href={bannable.image}>
                        {bannable.image}
                    </a>
                </div>
            </Details>
        </main>
    );
};

export default BannableDetails;