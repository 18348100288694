import React, { useState } from "react";
import { GoSearch, GoX } from "react-icons/go";

interface SearchFieldProps {
  query: string | null;
  setQuery(query: string | null): void;
}

const SearchField = (props: SearchFieldProps) => {
  const [query, setQuery] = useState<string | null>(props.query);

  const handleKeywordKeyPress = (e: React.KeyboardEvent) => {
    if (e.key == "Enter") props.setQuery(query);
  };

  return (
    <div className="relative flex h-10 rounded-md border border-lightSection">
      <input
        className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-full"
        type="text"
        value={query ?? ""}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={(e) => handleKeywordKeyPress(e)}
      />
      {query && (
        <div
          className="absolute right-9 text-slate-500 justify-center h-full hover:cursor-pointer flex items-center px-2"
          onClick={() => {
            setQuery(null);
            props.setQuery(null);
          }}
        >
          <GoX />
        </div>
      )}
      <div
        onClick={() => props.setQuery(query)}
        className="rounded-md bg-darkBg hover:bg-slate-800/70 hover:cursor-pointer"
      >
        <GoSearch className="mx-3 text-white h-full " />
      </div>
    </div>
  );
};

export default SearchField;
