import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { SeriesModel, EditableSeries } from "./models";
import config from "../../config/config";

export interface BracketState {
  series: SeriesModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (series: EditableSeries) => void;
  editState: RequestState;
  edit: (id: string, series: EditableSeries) => void;
  reset: () => void;
}

const useSeriesStore = create<BracketState>()((set) => ({
  series: {} as SeriesModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<SeriesModel>(
        `${config.apiUrl}/series/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        series: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (series: EditableSeries) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<SeriesModel>(
        `${config.apiUrl}/series`,
        series,
        { withCredentials: true }
      );
      set(() => ({
        series: response.data,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, series: EditableSeries) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<SeriesModel>(
        `${config.apiUrl}/series/${id}`,
        series,
        { withCredentials: true }
      );
      set(() => ({
        series: response.data,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useSeriesStore;
