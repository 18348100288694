import React, { ReactNode } from "react";

export interface SearchableTableResultProps {
  children: ReactNode[];
}

export const SearchableTableResult = (props: SearchableTableResultProps) => {

  return (
    <tr className="text-center font-medium truncate">
      {props.children.map((child, index) =>
        <td key={index} className="p-3">
          {child}
        </td>
      )}
    </tr>
  )
}

export default SearchableTableResult;