import React, { ReactNode } from "react";

export interface DetailsProps {
    header: string;
    keys: string[];
    isLoading?: boolean;
    isError?: boolean;
    children: ReactNode[]
}

export const Details = (props: DetailsProps) => {
    if (props.isLoading) {
        return (
            <>
                <span className="text-center text-white">Loading...</span>
            </>
        )
    }

    if (props.isError) {
        return (
            <>
                <span className="text-center text-red-300">Failed to load details</span>
            </>
        )
    }

    return (
        <main className="flex flex-col h-full p-5">
            <table className="bg-black bg-opacity-20 text-base mb-2 text-gray-300 rounded-xl overflow-hidden">
                <thead className="text-base uppercase bg-black bg-opacity-20">
                    <tr>
                        <th scope="col" className="py-3 px-6 text-start">{props.header}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="flex">
                        <td className="flex flex-col text-center w-48 font-bold justify-evenly">
                            {props.keys.map((key, index) =>
                                <span key={index} className={`p-3 bg-black ${index % 2 === 0 ? "bg-opacity-20" : "bg-opacity-5"}`}>
                                    {key}
                                </span>
                            )}
                        </td>
                        <td className="flex flex-col w-full font-light">
                            {props.children.map((child, index) =>
                                <div key={index} className={`p-3 bg-black ${index % 2 === 0 ? "bg-opacity-20" : "bg-opacity-5"}`}>
                                    {child}
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </main>
    );
};

export default Details;