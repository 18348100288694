import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { FantasyModel, EditableFantasy } from "./models";
import config from "../../config/config";

export interface FantasyState {
  fantasy: FantasyModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (fantasy: EditableFantasy) => void;
  editState: RequestState;
  edit: (id: string, fantasy: EditableFantasy) => void;
  reset: () => void;
}

const useFantasyStore = create<FantasyState>()((set) => ({
  fantasy: {} as FantasyModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<FantasyModel>(
        `${config.apiUrl}/fantasy/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        fantasy: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (fantasy: EditableFantasy) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableFantasy>(
        `${config.apiUrl}/fantasy`,
        fantasy,
        { withCredentials: true }
      );
      set(() => ({
        fantasy: response.data as FantasyModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, fantasy: EditableFantasy) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableFantasy>(
        `${config.apiUrl}/fantasy/${id}`,
        fantasy,
        { withCredentials: true }
      );
      set(() => ({
        fantasy: response.data as FantasyModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useFantasyStore;
