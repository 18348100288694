import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/bannables/bannable";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditBannable = () => {
    let { id } = useParams();

    const bannable = useStore((state) => state.bannable);
    const fetchState = useStore((state) => state.fetchState);
    const fetch = useStore((state) => state.fetch);
    const editState = useStore((state) => state.editState);
    const edit = useStore((state) => state.edit);
    const reset = useStore((state) => state.reset);

    useEffect(() => {
        if (id !== undefined) fetch(id);
    }, [fetch, id]);

    useEffect(() => {
        return () => reset();
    }, [reset]);

    const [name, setName] = useState<string>(bannable.name);
    const [image, setImage] = useState<string>(bannable.image);

    useEffect(() => {
        setName(bannable.name);
        setImage(bannable.image);
    }, [bannable]);

    return (
        <main className="flex flex-col w-full p-5">
            <Edit
                header="Edit Bannable"
                keys={["Id", "Name", "Image"]}
                save={() => edit(bannable.id, { name: name, image: image })}
                isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
                isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
                isSuccess={editState === RequestState.Complete}>
                <span>{bannable.id}</span>
                <input
                    className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
                    type="text"
                    value={name ?? ""}
                    onChange={(e) => setName(e.target.value)} />
                <input
                    className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
                    type="text"
                    value={image ?? ""}
                    onChange={(e) => setImage(e.target.value)} />
            </Edit>
        </main>
    );
};

export default EditBannable;