import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/vetoes/veto";
import RequestState from "../../state/shared/requestState";
import Create from "../shared/create";
import { VetoType } from "../../state/vetoes/vetoes";

export const CreateVeto = () => {
  const { id } = useParams();

  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [index, setIndex] = useState<number>(0);
  const [action, setAction] = useState<VetoType>(VetoType.Unknown);
  const [actionByTeamName, setActionByTeamName] = useState<string | null>(null);
  const [actionByTeamReference, setActionByTeamReference] = useState<string | null>(null);
  const [actionOnName, setActionOnName] = useState<string>("");
  const [seriesId, setSeriesId] = useState<string>(id ?? "");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Veto"
        keys={["Index", "Action", "Action On", "Team Name", "Team Reference", "Series Id"]}
        submit={() => create({ index: index, action: action, actionByTeamName: actionByTeamName, actionByTeamReference: actionByTeamReference, actionOnName: actionOnName, seriesId: seriesId })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={index}
          onChange={(e) => setIndex(Number(e.target.value))} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={action}
          onChange={(e) => setAction(e.target.value as unknown as VetoType)}>
          <option value={VetoType.Unknown}>Unknown</option>
          <option value={VetoType.Ban}>Ban</option>
          <option value={VetoType.Pick}>Pick</option>
          <option value={VetoType.Leftover}>Leftover</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={actionOnName ?? ""}
          onChange={(e) => setActionOnName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={actionByTeamName ?? ""}
          onChange={(e) => setActionByTeamName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={actionByTeamReference ?? ""}
          onChange={(e) => setActionByTeamReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesId ?? ""}
          onChange={(e) => setSeriesId(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateVeto;