import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/results/result";
import { ResultType } from "../../state/results/results";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Details from "../shared/details";

export const ResultDetails = () => {
  let { id } = useParams();

  const result = useStore((state) => state.result);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Result Details"
        keys={["Id", "Type", "Score", "Series Id", "Match Id", "Series Team Id"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{result.id}</span>
        <span>{ResultType[result.resultState]}</span>
        <span>{result.score}</span>
        <span>{result.seriesId}</span>
        <span>{result.matchId}</span>
        <span>{result.seriesTeamId}</span>
      </Details>
    </main>
  );
};

export default ResultDetails;