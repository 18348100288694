import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/match_players/player";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const MatchPlayerDetails = () => {
  let { id } = useParams();

  const player = useStore((state) => state.player);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Player Details"
        keys={["Id", "Name", "Reference", "Team Reference", "Match Id"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{player.id}</span>
        <span>{player.name}</span>
        <span>{player.reference}</span>
        <span>{player.teamReference}</span>
        <span>{player.matchId}</span>
      </Details>
    </main>
  );
};

export default MatchPlayerDetails;