import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/tournaments/tournament";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";
import GameType from "../../state/shared/gameType";

export const TournamentDetails = () => {
  let { id } = useParams();

  const tournament = useStore((state) => state.tournament);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Tournament Details"
        keys={["Id", "Name", "Tag", "Game", "Start Time", "Reference", "External Link", "Organizer Id", "Image"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{tournament.id}</span>
        <span>{tournament.name}</span>
        <span>{tournament.tag}</span>
        <span>{GameType[tournament.game]}</span>
        <span>{tournament.startTime}</span>
        <span>{tournament.reference}</span>
        <span>{tournament.externalLink}</span>
        <span>{tournament.organizerId}</span>
        <div className="absolute">
          <a href={tournament.logo}>
            {tournament.logo}
          </a>
          <img alt="Tournament" src={tournament.logo} />
        </div>
      </Details>
    </main>
  );
};

export default TournamentDetails;