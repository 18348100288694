import React, { ReactNode, useState } from "react";
import { GiCheckMark } from "react-icons/gi";

export interface ReferenceMappingListProps {
  header: string;
  add: (reference: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  children: ReactNode[]
}

export const ReferenceMappingList = (props: ReferenceMappingListProps) => {
  const [reference, setReference] = useState<string>("");

  const add = (reference: string) => {
    props.add(reference);
    setReference("");
  }

  return (
    <section className="flex flex-col relative h-full p-5">
      <table className="bg-black bg-opacity-20 text-base mb-2 text-gray-300 rounded-xl overflow-hidden">
        <thead className="text-base uppercase bg-black bg-opacity-20">
          <tr>
            <th scope="col" className="flex py-3 px-6 justify-between">
              <span>{props.header}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.children.map((child, index) =>
            <tr key={index} className="font-medium truncate">
              <td className={`flex bg-black ${index % 2 === 0 ? "bg-opacity-10" : "bg-opacity-5"}`}>
                {child}
              </td>
            </tr>)}
          <tr>
            <td className="flex flex-col p-3 w-full font-light">
              {props.isSuccess && <span className="text-green-400 text-lg">Successfully executed the request</span>}
              {props.isError && <span className="text-red-400 text-lg">Failed to execute the request</span>}
              <div className="flex gap-x-3">
                <input
                  className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-12"
                  type="text"
                  placeholder="Reference"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)} />
                <button
                  disabled={reference === ""}
                  onClick={() => add(reference)}
                  className="rounded-md bg-darkBg hover:bg-slate-800/70">
                  <GiCheckMark className="mx-3 text-green-400 h-full " />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default ReferenceMappingList;