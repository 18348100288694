import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/players/player";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const PlayerDetails = () => {
  let { id } = useParams();

  const player = useStore((state) => state.player);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Player Details"
        keys={["Id", "Alias", "Name", "Birthday", "Image"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{player.id}</span>
        <span>{player.alias}</span>
        <span>{player.name}</span>
        <span>{player.birthday}</span>
        <div>
          <img alt="Player" src={player.image} />
          <a href={player.image}>
            {player.image}
          </a>
        </div>
      </Details>
    </main>
  );
};

export default PlayerDetails;