import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/fantasy/fantasy";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditFantasy = () => {
  let { id } = useParams();

  const fantasy = useStore((state) => state.fantasy);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [tournamentId, setTournamentId] = useState<string>(fantasy.tournamentId);

  useEffect(() => {
    setTournamentId(fantasy.tournamentId);
  }, [fantasy]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Fantasy"
        keys={["Id", "Tournament Id"]}
        save={() => edit(fantasy.id, { tournamentId: tournamentId })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{fantasy.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tournamentId ?? ""}
          onChange={(e) => setTournamentId(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditFantasy;