import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/brackets/bracket";
import { BracketType } from "../../state/brackets/brackets";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Create from "../shared/create";

export const CreateBracket = () => {
  const { id } = useParams();

  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [index, setIndex] = useState<number>(0);
  const [reference, setReference] = useState<string>("");
  const [name, setName] = useState<string | null>(null);
  const [type, setType] = useState<BracketType>(BracketType.Unknown);
  const [state, setState] = useState<State>(State.Unknown);
  const [tournamentId, setTournamentId] = useState<string>(id ?? "");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Bracket"
        keys={["Index", "Reference", "Name", "Type", "State", "Tournament Id"]}
        submit={() => create({ index: index, reference: reference, name: name, type: type, state: state, tournamentId: tournamentId })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={index}
          onChange={(e) => setIndex(Number(e.target.value))} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={type}
          onChange={(e) => setType(e.target.value as unknown as BracketType)}>
          <option value={BracketType.Unknown}>Unknown</option>
          <option value={BracketType.RoundRobin}>Round Robin</option>
          <option value={BracketType.Elimination}>Elimination</option>
          <option value={BracketType.Swiss}>Swiss</option>
          <option value={BracketType.DoubleEliminationUpper}>Upper Bracket</option>
          <option value={BracketType.DoubleEliminationLower}>Lower Bracket</option>
          <option value={BracketType.DoubleEliminationFinal}>Final Bracket</option>
        </select>
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={state}
          onChange={(e) => setState(e.target.value as unknown as State)}>
          <option value={State.Unknown}>Unknown</option>
          <option value={State.Ongoing}>Ongoing</option>
          <option value={State.Upcoming}>Upcoming</option>
          <option value={State.Completed}>Completed</option>
          <option value={State.Invalid}>Invalid</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tournamentId ?? ""}
          onChange={(e) => setTournamentId(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateBracket;