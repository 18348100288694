import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import BannableDetails from "./components/bannables/detailsPage";
import Bannables from "./components/bannables/bannablesPage";
import Header from "./components/header/header";
import Login from "./components/login/loginPage";
import Sidebar from "./components/sidebar/sidebar";
import EditBannable from "./components/bannables/editPage";
import CreateBannable from "./components/bannables/createPage";
import CreateMap from "./components/maps/createPage";
import EditMap from "./components/maps/editPage";
import MapDetails from "./components/maps/detailsPage";
import Maps from "./components/maps/mapsPage";
import Socials from "./components/socials/socialsPage";
import SocialDetails from "./components/socials/detailsPage";
import EditSocial from "./components/socials/editPage";
import CreateSocial from "./components/socials/createPage";
import CreatePlayer from "./components/players/createPage";
import EditPlayer from "./components/players/editPage";
import PlayerDetails from "./components/players/detailsPage";
import Players from "./components/players/playersPage";
import CreateTeam from "./components/teams/createPage";
import EditTeam from "./components/teams/editPage";
import TeamDetails from "./components/teams/detailsPage";
import Teams from "./components/teams/teamsPage";
import CreateOrganizer from "./components/organizers/createPage";
import EditOrganizer from "./components/organizers/editPage";
import OrganizerDetails from "./components/organizers/detailsPage";
import Organizers from "./components/organizers/organizersPage";
import CreateTournament from "./components/tournaments/createPage";
import EditTournament from "./components/tournaments/editPage";
import TournamentDetails from "./components/tournaments/detailsPage";
import Tournaments from "./components/tournaments/tournamentsPage";
import CreateBracket from "./components/brackets/createPage";
import EditBracket from "./components/brackets/editPage";
import BracketDetails from "./components/brackets/detailsPage";
import Brackets from "./components/brackets/bracketsPage";
import Streamers from "./components/streamers/streamersPage";
import StreamerDetails from "./components/streamers/detailsPage";
import EditStreamer from "./components/streamers/editPage";
import CreateStreamer from "./components/streamers/createPage";
import CreateSeries from "./components/series/createPage";
import EditSeries from "./components/series/editPage";
import SeriesDetails from "./components/series/detailsPage";
import Series from "./components/series/seriesPage";
import CreateMatch from "./components/matches/createPage";
import EditMatch from "./components/matches/editPage";
import MatchDetails from "./components/matches/detailsPage";
import Matches from "./components/matches/matchesPage";
import CreateResult from "./components/results/createPage";
import EditResult from "./components/results/editPage";
import ResultDetails from "./components/results/detailsPage";
import Results from "./components/results/resultsPage";
import CreateSeriesTeam from "./components/series_teams/createPage";
import EditSeriesTeam from "./components/series_teams/editPage";
import SeriesTeamDetails from "./components/series_teams/detailsPage";
import SeriesTeams from "./components/series_teams/seriesTeamPage";
import CreateMatchPlayer from "./components/match_players/createPage";
import EditMatchPlayer from "./components/match_players/editPage";
import MatchPlayerDetails from "./components/match_players/detailsPage";
import MatchPlayers from "./components/match_players/matchPlayersPage";
import CreateVeto from "./components/vetoes/createPage";
import EditVeto from "./components/vetoes/editPage";
import VetoDetails from "./components/vetoes/detailsPage";
import Vetoes from "./components/vetoes/vetoesPage";
import axios from "axios";
import Fantasy from "./components/fantasy/fantasyPage";
import FantasyDetails from "./components/fantasy/detailsPage";
import EditFantasy from "./components/fantasy/editPage";
import CreateFantasy from "./components/fantasy/createPage";

function Routing() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  axios.interceptors.request.use(async function (config) {
    if (!isAuthenticated) return config;

    const token = await getAccessTokenSilently();
    if (config.headers) {
      config.headers = { ...config.headers, "Authorization": `Bearer ${token}` }
    } else {
      config.headers = { "Authorization": `Bearer ${token}` }
    }

    return config;
  });

  return (
    <BrowserRouter>
      <div className="bg-darkBg font-albertSans min-h-screen h-full -z-20">

        {(isLoading || !isAuthenticated) && (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        )}

        {!isLoading && isAuthenticated &&
          <div className="h-full w-full">
            <Header />
            <div className="flex h-full justify-start w-full">
              <Sidebar />
              <div className="flex w-full h-full">
                <Routes>

                  <Route path="/match/new/:id" element={<CreateMatch />} />
                  <Route path="/match/new" element={<CreateMatch />} />
                  <Route path="/match/:id/edit" element={<EditMatch />} />
                  <Route path="/match/:id" element={<MatchDetails />} />
                  <Route path="/matches/:query" element={<Matches />} />
                  <Route path="/matches" element={<Matches />} />

                  <Route path="/team/new" element={<CreateTeam />} />
                  <Route path="/team/:id/edit" element={<EditTeam />} />
                  <Route path="/team/:id" element={<TeamDetails />} />
                  <Route path="/teams" element={<Teams />} />

                  <Route path="/player/new" element={<CreatePlayer />} />
                  <Route path="/player/:id/edit" element={<EditPlayer />} />
                  <Route path="/player/:id" element={<PlayerDetails />} />
                  <Route path="/players" element={<Players />} />

                  <Route path="/social/new" element={<CreateSocial />} />
                  <Route path="/social/:id/edit" element={<EditSocial />} />
                  <Route path="/social/:id" element={<SocialDetails />} />
                  <Route path="/socials" element={<Socials />} />

                  <Route path="/bannable/new" element={<CreateBannable />} />
                  <Route path="/bannable/:id/edit" element={<EditBannable />} />
                  <Route path="/bannable/:id" element={<BannableDetails />} />
                  <Route path="/bannables" element={<Bannables />} />

                  <Route path="/map/new" element={<CreateMap />} />
                  <Route path="/map/:id/edit" element={<EditMap />} />
                  <Route path="/map/:id" element={<MapDetails />} />
                  <Route path="/maps" element={<Maps />} />

                  <Route path="/organizer/new" element={<CreateOrganizer />} />
                  <Route path="/organizer/:id/edit" element={<EditOrganizer />} />
                  <Route path="/organizer/:id" element={<OrganizerDetails />} />
                  <Route path="/organizers" element={<Organizers />} />

                  <Route path="/tournament/new/:id" element={<CreateTournament />} />
                  <Route path="/tournament/new" element={<CreateTournament />} />
                  <Route path="/tournament/:id/edit" element={<EditTournament />} />
                  <Route path="/tournament/:id" element={<TournamentDetails />} />
                  <Route path="/tournaments/:query" element={<Tournaments />} />
                  <Route path="/tournaments" element={<Tournaments />} />

                  <Route path="/bracket/new/:id" element={<CreateBracket />} />
                  <Route path="/bracket/new" element={<CreateBracket />} />
                  <Route path="/bracket/:id/edit" element={<EditBracket />} />
                  <Route path="/bracket/:id" element={<BracketDetails />} />
                  <Route path="/brackets/:query" element={<Brackets />} />
                  <Route path="/brackets" element={<Brackets />} />

                  <Route path="/series/new/:id" element={<CreateSeries />} />
                  <Route path="/series/new" element={<CreateSeries />} />
                  <Route path="/series/:id/edit" element={<EditSeries />} />
                  <Route path="/series/:id" element={<SeriesDetails />} />
                  <Route path="/series/search/:query" element={<Series />} />
                  <Route path="/series/search" element={<Series />} />

                  <Route path="/seriesteam/new/:id" element={<CreateSeriesTeam />} />
                  <Route path="/seriesteam/new" element={<CreateSeriesTeam />} />
                  <Route path="/seriesteam/:id/edit" element={<EditSeriesTeam />} />
                  <Route path="/seriesteam/:id" element={<SeriesTeamDetails />} />
                  <Route path="/seriesteams/:query" element={<SeriesTeams />} />
                  <Route path="/seriesteams" element={<SeriesTeams />} />

                  <Route path="/matchplayer/new/:id" element={<CreateMatchPlayer />} />
                  <Route path="/matchplayer/new" element={<CreateMatchPlayer />} />
                  <Route path="/matchplayer/:id/edit" element={<EditMatchPlayer />} />
                  <Route path="/matchplayer/:id" element={<MatchPlayerDetails />} />
                  <Route path="/matchplayers/:query" element={<MatchPlayers />} />
                  <Route path="/matchplayers" element={<MatchPlayers />} />

                  <Route path="/veto/new/:id" element={<CreateVeto />} />
                  <Route path="/veto/new" element={<CreateVeto />} />
                  <Route path="/veto/:id/edit" element={<EditVeto />} />
                  <Route path="/veto/:id" element={<VetoDetails />} />
                  <Route path="/vetoes/:query" element={<Vetoes />} />
                  <Route path="/vetoes" element={<Vetoes />} />

                  <Route path="/result/new/:id" element={<CreateResult />} />
                  <Route path="/result/new" element={<CreateResult />} />
                  <Route path="/result/:id/edit" element={<EditResult />} />
                  <Route path="/result/:id" element={<ResultDetails />} />
                  <Route path="/results/:query" element={<Results />} />
                  <Route path="/results" element={<Results />} />

                  <Route path="/streamer/new" element={<CreateStreamer />} />
                  <Route path="/streamer/:id/edit" element={<EditStreamer />} />
                  <Route path="/streamer/:id" element={<StreamerDetails />} />
                  <Route path="/streamers" element={<Streamers />} />

                  <Route path="/fantasy/new" element={<CreateFantasy />} />
                  <Route path="/fantasy/:id/edit" element={<EditFantasy />} />
                  <Route path="/fantasy/:id" element={<FantasyDetails />} />
                  <Route path="/fantasy" element={<Fantasy />} />

                  <Route path="/" element={<div className="w-full">Auth</div>} />
                  {/* <Route path="*" element={<NotFound/>}/> */}
                </Routes>

              </div>
            </div>
          </div>
        }

      </div >
    </BrowserRouter>
  );
}

export default Routing;
