export interface EditableFantasy {
  tournamentId: string;
}

export interface FantasyModel extends EditableFantasy {
  id: string;
}

export enum FantasyRoles {
  Unknown,
  Rifler,
  Sniper,
  Leader,
  Support
}

export interface EditableFantasyPlayer {
  name: string;
  reference: string;
  role: FantasyRoles;
  teamId: string | null;
  teamName: string;
  price: number;
  fantasyId: string;
}

export interface FantasyPlayerModel extends EditableFantasyPlayer {
  id: string;
}

export interface EditableFantasyRound {
  index: number;
  fantasyId: string;
}

export interface FantasyRoundModel extends EditableFantasyRound {
  id: string;
  series: FantasyRoundToSeries[];
}

export interface EditableFantasyRoundToSeries {
  roundId: string;
  seriesId: string;
}

export interface FantasyRoundToSeries extends EditableFantasyRoundToSeries {
  id: string;
}