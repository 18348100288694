import React from "react";
import LogoComponent from "./logoComponent";
import LogoutComponent from "./logoutComponent";
import { NavLink } from "react-router-dom";

export const Header = () => {
  return (
    <header className="flex items-center justify-center bg-darkBg border-b border-b-lightSection text-white z-10 h-16">
      <div className="flex max-w-7xl justify-between w-full">
        <NavLink to="/">
          <LogoComponent />
        </NavLink>
        <LogoutComponent />
      </div>
    </header>
  )
};

export default Header;