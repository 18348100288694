import React, { useEffect, useState } from "react";
import useStore from "../../state/tournaments/tournament";
import RequestState from "../../state/shared/requestState";
import Create from "../shared/create";
import GameType from "../../state/shared/gameType";
import { useParams } from "react-router-dom";

export const CreateTournament = () => {
  let { id } = useParams();

  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [name, setName] = useState<string>("");
  const [tag, setTag] = useState<string | null>(null);
  const [logo, setLogo] = useState<string>("");
  const [game, setGame] = useState<GameType>(GameType.Varied);
  const [reference, setReference] = useState<string>("");
  const [startTime, setStartTime] = useState<string | null>(null);
  const [externalLink, setExternalLink] = useState<string | null>(null);
  const [organizerId, setOrganizerId] = useState<string>(id ?? "");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Tournament"
        keys={["Name", "Tag", "Logo", "Game", "Reference", "Start Time", "External Link", "Organizer Id"]}
        submit={() => create({ name: name, tag: tag, logo: logo, game: game, reference: reference, startTime: startTime, externalLink: externalLink, organizerId: organizerId })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tag ?? ""}
          onChange={(e) => setTag(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={logo ?? ""}
          onChange={(e) => setLogo(e.target.value)} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={game}
          onChange={(e) => setGame(e.target.value as unknown as GameType)}>
          <option value={GameType.Varied}>Varied</option>
          <option value={GameType.CSGO}>CSGO</option>
          <option value={GameType.CS2}>CS2</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={startTime ?? ""}
          onChange={(e) => setStartTime(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={externalLink ?? ""}
          onChange={(e) => setExternalLink(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={organizerId ?? ""}
          onChange={(e) => setOrganizerId(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateTournament;