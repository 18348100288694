import create from "zustand";
import axios from "axios";
import SearchResult from "../shared/searchResult";
import RequestState from "../shared/requestState";
import SearchRequest from "../shared/searchRequest";
import { RoutineData, TournamentListItem, TournamentModel } from "./tournaments";
import config from "../../config/config";

export interface TournamentsSearchState {
  result: SearchResult<TournamentListItem[]>;
  requestState: RequestState;
  search: (request: SearchRequest) => void;
  deleteState: RequestState;
  delete: (id: string) => void;
  detailsRoutineState: RequestState;
  startDetailsRoutine: (id: string, routineData: RoutineData) => void;
  seriesRoutineState: RequestState;
  startSeriesRoutine: (id: string, routineData: RoutineData) => void;
  reset: () => void;
}

const useTournamentsSearchStore = create<TournamentsSearchState>()((set) => ({
  result: {
    total: 0,
    result: []
  },
  requestState: RequestState.NotStarted,
  search: async (request: SearchRequest) => {
    set(() => ({ requestState: RequestState.Loading }));
    try {
      const response = await axios.post<SearchResult<TournamentListItem[]>>(
        `${config.apiUrl}/tournaments/search`,
        request,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        requestState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ requestState: RequestState.Error }));
    }
  },
  deleteState: RequestState.NotStarted,
  delete: async (id: string) => {
    set(() => ({ deleteState: RequestState.Loading }));
    try {
      const response = await axios.delete<TournamentModel>(
        `${config.apiUrl}/tournaments/${id}`,
        { withCredentials: true }
      );
      const deleted = response.data as TournamentModel;
      set((state) => ({
        result: { ...state.result, result: state.result.result.filter(x => x.tournament.id !== deleted.id) },
        deleteBannableState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ deleteState: RequestState.Error }));
    }
  },
  detailsRoutineState: RequestState.NotStarted,
  startDetailsRoutine: async (id: string, routineData: RoutineData) => {
    set(() => ({ detailsRoutineState: RequestState.Loading }));
    try {
      await axios.post(
        `${config.apiUrl}/tournaments/${id}/routines/details`,
        routineData,
        { withCredentials: true }
      );
      set(() => ({
        detailsRoutineState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ detailsRoutineState: RequestState.Error }));
    }
  },
  seriesRoutineState: RequestState.NotStarted,
  startSeriesRoutine: async (id: string, routineData: RoutineData) => {
    set(() => ({ seriesRoutineState: RequestState.Loading }));
    try {
      await axios.post(
        `${config.apiUrl}/tournaments/${id}/routines/series`,
        routineData,
        { withCredentials: true }
      );
      set(() => ({
        seriesRoutineState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ seriesRoutineState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      deleteState: RequestState.NotStarted,
      detailsRoutineState: RequestState.NotStarted,
      seriesRoutineState: RequestState.NotStarted
    }));
  }
}));

export default useTournamentsSearchStore;
