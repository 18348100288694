import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/socials/social";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditSocial = () => {
  let { id } = useParams();

  const social = useStore((state) => state.social);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [name, setName] = useState<string>(social.name);
  const [image, setImage] = useState<string>(social.image);

  useEffect(() => {
    setName(social.name);
    setImage(social.image);
  }, [social]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Social"
        keys={["Id", "Name", "Image"]}
        save={() => edit(social.id, { name: name, image: image })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{social.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={image ?? ""}
          onChange={(e) => setImage(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditSocial;