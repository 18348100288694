import React from "react";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutComponent = () => {
  const { logout } = useAuth0();

  return (
    <button
      className="flex gap-2 hover:underline"
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      <span className="font-extralight my-auto text-lg uppercase">
        Logout
      </span>
      <RiLogoutBoxRLine className="text-white h-full " />
    </button>

  )
};

export default LogoutComponent;