import React, { ReactNode, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import RequestState from "../../state/shared/requestState";
import useSocialsSearchStore from "../../state/socials/search";
import { SocialModel } from "../../state/socials/socials";
import SearchableDropdown from "./searchableDropdown";

export interface SocialMappingListProps {
  header: string;
  add: (socialId: string, link: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  children: ReactNode[]
}

export const SocialMappingList = (props: SocialMappingListProps) => {
  const socials = useSocialsSearchStore((state) => state.result);
  const socialsSearchState = useSocialsSearchStore((state) => state.requestState);
  const searchSocials = useSocialsSearchStore((state) => state.search);

  const [social, setSocial] = useState<SocialModel | null>(null);
  const [link, setLink] = useState<string>("");

  const add = (socialId: string, link: string) => {
    props.add(socialId, link);
    setLink("");
  }

  return (
    <section className="flex flex-col relative h-full p-5">
      <table className="bg-black bg-opacity-20 text-base mb-2 text-gray-300 rounded-xl overflow-hidden">
        <thead className="text-base uppercase bg-black bg-opacity-20">
          <tr>
            <th scope="col" className="flex py-3 px-6 justify-between">
              <span>{props.header}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.children.map((child, index) =>
            <tr key={index} className="font-medium truncate">
              <td className={`flex bg-black ${index % 2 === 0 ? "bg-opacity-10" : "bg-opacity-5"}`}>
                {child}
              </td>
            </tr>)}
          <tr>
            <td className="flex flex-col p-3 w-full font-light">
              {props.isSuccess && <span className="text-green-400 text-lg">Successfully executed the request</span>}
              {props.isError && <span className="text-red-400 text-lg">Failed to execute the request</span>}
              <div className="flex gap-x-3">
                <SearchableDropdown
                  total={socials.total}
                  headers={["Name", "Image"]}
                  selected={social ? social.name : null}
                  search={(request) => searchSocials(request)}
                  isLoading={socialsSearchState === RequestState.Loading}
                  isError={socialsSearchState === RequestState.Error}>
                  {socials.result.map((social, index) =>
                    <button
                      key={index}
                      onClick={() => setSocial(social)}
                      className="flex p-1 text-start gap-x-4 items-center hover:bg-slate-800/70 hover:cursor-pointer">
                      <img alt="Social" width={36} src={social.image} />
                      {social.name}
                    </button>)}
                </SearchableDropdown>
                <input
                  className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full "
                  type="text"
                  placeholder="Link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)} />
                <button
                  disabled={social === null || link === ""}
                  onClick={() => add(social!.id, link)}
                  className="rounded-md bg-darkBg hover:bg-slate-800/70">
                  <GiCheckMark className="mx-3 text-green-400 h-full " />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default SocialMappingList;