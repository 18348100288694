import React, { useEffect, useState } from "react";
import useStore from "../../state/players/player";
import RequestState from "../../state/shared/requestState";
import Create from "../shared/create";

export const CreatePlayer = () => {
  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [alias, setAlias] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [image, setImage] = useState<string>("");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Player"
        keys={["Alias", "Name", "Birthday", "Image"]}
        submit={() => create({ alias: alias, name: name, birthday: birthday, image: image })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={alias ?? ""}
          onChange={(e) => setAlias(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={birthday ?? ""}
          onChange={(e) => setBirthday(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={image ?? ""}
          onChange={(e) => setImage(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreatePlayer;