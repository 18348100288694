export interface Filter {
  operation: Operation
  field: string;
  terms: Term[];
}

export enum Operation {
  And, Or
}

export enum Condition {
  Equals, NotEquals, LessThan, GreaterThan
}

export interface Term {
  operation: Operation;
  condition: Condition;
  value: string;
}

export interface Ordering {
  field: string;
  descending: boolean;
}

export interface SearchRequest {
  query: string | null;
  offset: number;
  limit: number;
  ordering: Ordering[] | null;
  filters: Filter[] | null;
}

export default SearchRequest;