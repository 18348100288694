import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/matches/match";
import GameType from "../../state/shared/gameType";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Create from "../shared/create";

export const CreateMatch = () => {
  const { id } = useParams();

  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [reference, setReference] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const [state, setState] = useState<State>(State.Unknown);
  const [game, setGame] = useState<GameType>(GameType.Varied);
  const [seriesId, setSeriesId] = useState<string>(id ?? "");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Series"
        keys={["Reference", "Index", "State", "Game", "Series Id"]}
        submit={() => create({ reference: reference, index: index, state: state, game: game, seriesId: seriesId })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={index}
          onChange={(e) => setIndex(Number(e.target.value))} />
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={state}
          onChange={(e) => setState(e.target.value as unknown as State)}>
          <option value={State.Unknown}>Unknown</option>
          <option value={State.Ongoing}>Ongoing</option>
          <option value={State.Upcoming}>Upcoming</option>
          <option value={State.Completed}>Completed</option>
          <option value={State.Invalid}>Invalid</option>
        </select>
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={game}
          onChange={(e) => setGame(e.target.value as unknown as GameType)}>
          <option value={GameType.Varied}>Varied</option>
          <option value={GameType.CSGO}>CS: GO</option>
          <option value={GameType.CS2}>CS2</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesId}
          onChange={(e) => setSeriesId(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateMatch;