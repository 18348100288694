import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { SocialModel, EditableSocial } from "./socials";
import config from "../../config/config";

export interface MatchState {
  social: SocialModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (social: EditableSocial) => void;
  editState: RequestState;
  edit: (id: string, social: EditableSocial) => void;
  reset: () => void;
}

const useSocialStore = create<MatchState>()((set) => ({
  social: {} as SocialModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<SocialModel>(
        `${config.apiUrl}/socials/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        social: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (social: EditableSocial) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableSocial>(
        `${config.apiUrl}/socials`,
        social,
        { withCredentials: true }
      );
      set(() => ({
        social: response.data as SocialModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, social: EditableSocial) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableSocial>(
        `${config.apiUrl}/socials/${id}`,
        social,
        { withCredentials: true }
      );
      set(() => ({
        social: response.data as SocialModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useSocialStore;
