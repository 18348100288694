import React, { useEffect } from "react";
import useStore from "../../state/tournaments/search";
import RequestState from "../../state/shared/requestState";
import { BsPencilFill } from "react-icons/bs";
import { NavLink, useParams } from "react-router-dom";
import SearchableTable from "../shared/searchableTable";
import SearchableTableResult from "../shared/searchableTableResult";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaPlus, FaTrash } from "react-icons/fa";
import GameType from "../../state/shared/gameType";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";

export const Tournaments = () => {
  const { query } = useParams();

  const result = useStore((state) => state.result);
  const request = useStore((state) => state.requestState);
  const search = useStore((state) => state.search);
  const deleteRequest = useStore((state) => state.deleteState);
  const deleteTournament = useStore((state) => state.delete);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <main className="flex flex-col h-full w-full p-5 gap-5">
      <NavLink to={"/tournament/new"}>
        <div className="flex space-x-3 p-3 place-items-center rounded-md h-full w-1/12 hover:bg-slate-800/70">
          <FaPlus className="text-white" />
          <span className="text-lg text-white">New</span>
        </div>
      </NavLink>

      {deleteRequest === RequestState.Complete && <span className="text-green-400 text-lg">Successfully deleted the data</span>}
      {deleteRequest === RequestState.Error && <span className="text-red-400 text-lg">Failed to delete the data</span>}
      <SearchableTable
        headers={[
          { text: "Name", selector: "name" },
          { text: "Tag", selector: "tag" },
          { text: "Id" },
          { text: "Logo" },
          { text: "Start Time", selector: "starttime" },
          { text: "Game", selector: "game" },
          { text: "Matches" },
          { text: "Teams" },
          { text: "Players" },
          { text: "Actions" }]}
        total={result.total}
        search={search}
        query={query}
        isLoading={request === RequestState.Loading}
        isError={request === RequestState.Error}>
        {result.result.map((r, index) =>
          <SearchableTableResult key={index}>
            <span>{r.tournament.name}</span>
            <span>{r.tournament.tag ?? ""}</span>
            <span>{r.tournament.id}</span>
            <div className="flex justify-center">
              <img alt="Tournament" height={64} width={64} src={r.tournament.logo} />
            </div>
            <span>{r.tournament.startTime}</span>
            <span>{GameType[r.tournament.game]}</span>
            <span>{r.numberOfMatches}</span>
            <span>{r.numberOfTeams}</span>
            <span>{r.numberOfPlayers}</span>
            <div className="flex justify-center gap-4">
              <NavLink to={"/bracket/new/" + r.tournament.id}>
                <FaPlus />
              </NavLink>
              <NavLink to={"/series/" + r.tournament.id}>
                <RiCheckboxMultipleBlankFill />
              </NavLink>
              <NavLink to={"/tournament/" + r.tournament.id}>
                <GiHamburgerMenu />
              </NavLink>
              <NavLink to={"/tournament/" + r.tournament.id + "/edit"}>
                <BsPencilFill />
              </NavLink>
              <button onClick={() => deleteTournament(r.tournament.id)}>
                <FaTrash />
              </button>
            </div>
          </SearchableTableResult>
        )}
      </SearchableTable>
    </main>
  );
};

export default Tournaments;