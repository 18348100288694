import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/socials/social";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const SocialDetails = () => {
  let { id } = useParams();

  const social = useStore((state) => state.social);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    // Runs after the first render() lifecycle
    if (id !== undefined) {
      fetch(id);
    }
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Social Details"
        keys={["Id", "Name", "Image"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{social.id}</span>
        <span>{social.name}</span>
        <div>
          <img alt="Social" src={social.image} />
          <a href={social.image}>
            {social.image}
          </a>
        </div>
      </Details>
    </main>
  );
};

export default SocialDetails;