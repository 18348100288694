import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { PlayerModel, EditablePlayer } from "./players";
import config from "../../config/config";

export interface PlayerState {
  player: PlayerModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (player: EditablePlayer) => void;
  editState: RequestState;
  edit: (id: string, player: EditablePlayer) => void;
  reset: () => void;
}

const usePlayerStore = create<PlayerState>()((set) => ({
  player: {} as PlayerModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<PlayerModel>(
        `${config.apiUrl}/matchplayers/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        player: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (player: EditablePlayer) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<PlayerModel>(
        `${config.apiUrl}/matchplayers`,
        player,
        { withCredentials: true }
      );
      set(() => ({
        player: response.data,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, player: EditablePlayer) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<PlayerModel>(
        `${config.apiUrl}/matchplayers/${id}`,
        player,
        { withCredentials: true }
      );
      set(() => ({
        player: response.data,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default usePlayerStore;
