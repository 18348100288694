import React from "react";
import {
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
  CgChevronRight,
  CgChevronLeft,
} from "react-icons/cg";

interface PaginationProps {
  total: number;
  offset: number;
  limit: number;
  setOffset: (offset: number) => void;
  setLimit: (offset: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const limits = [10, 25, 50, 100];
  const currentPage = props.offset / props.limit;
  const pages = Math.floor(props.total / props.limit);
  const pageNos =
    currentPage === 0
      ? [0, 1, 2]
      : currentPage === pages
      ? [-2, -1, 0]
      : [-1, 0, 1];

  return (
    <div className="flex flex-col items-center sm:items-end mb-5">
      <div className="flex w-full justify-between">
        <span className="text-slate-500 hidden sm:flex w-full items-center">
          Fjöldi niðurstaða
        </span>
        <span className="py-2 text-slate-500 text-center w-full sm:text-right">
          Sýni {currentPage === 0 ? "1" : currentPage * props.limit + 1} -{" "}
          {currentPage === pages
            ? props.total
            : currentPage * props.limit + props.limit}{" "}
          af {props.total} niðurstöðum
        </span>
      </div>

      <div className="flex w-full items-center flex-col-reverse gap-4 justify-between text-white sm:flex-row">
        <div className="flex space-x-1 place-self-center rounded-md">
          {limits.map((l) => (
            <div
              key={l}
              className={`rounded-md px-4 py-2 z-10 border border-lightSection  ${
                props.limit !== l
                  ? "hover:bg-black hover:bg-opacity-20 hover:cursor-pointer"
                  : "bg-darkSection"
              }`}
              onClick={() => props.setLimit(l)}
            >
              {l}
            </div>
          ))}
        </div>
        <span className="text-slate-500 sm:hidden flex">Fjöldi niðurstaða</span>
        {props.limit < props.total && (
          <div className="flex space-x-1 place-self-center rounded-md text-white">
            <div
              className={`rounded-md px-2 py-3 z-10 border border-lightSection  ${
                props.offset !== 0
                  ? "hover:bg-slate-800/70 hover:cursor-pointer"
                  : "text-gray-500"
              }`}
              onClick={() => props.offset !== 0 && props.setOffset(0)}
            >
              <CgChevronDoubleLeft className="h-full w-full" />
            </div>
            <div
              className={`rounded-md px-2 py-3 z-10 border border-lightSection  ${
                props.offset !== 0
                  ? "hover:bg-slate-800/70 hover:cursor-pointer"
                  : "text-gray-500"
              }`}
              onClick={() =>
                props.offset !== 0 &&
                props.setOffset(props.offset - props.limit)
              }
            >
              <CgChevronLeft className="h-full w-full" />
            </div>

            {pageNos.map((n) => {
              const pageNo = currentPage + n;
              const isValid =
                pageNo >= 0 && pageNo <= pages && pageNo !== currentPage;
              const clicked = () => {
                if (!isValid) return;

                props.setOffset(props.limit * pageNo);
              };

              return (
                <div
                  key={pageNo}
                  className={`rounded-md px-4 py-2 z-10 border border-lightSection ${
                    isValid
                      ? "hover:bg-black hover:bg-opacity-20 hover:cursor-pointer"
                      : "bg-darkSection"
                  }`}
                  onClick={() => clicked()}
                >
                  {pageNo + 1}
                </div>
              );
            })}

            <div
              className={`rounded-md px-2 py-3 z-10 border border-lightSection  ${
                props.offset !== props.limit * pages
                  ? "hover:bg-slate-800/70 hover:cursor-pointer"
                  : "text-gray-500"
              }`}
              onClick={() =>
                props.offset !== props.limit * pages &&
                props.setOffset(props.offset + props.limit)
              }
            >
              <CgChevronRight className="h-full w-full" />
            </div>
            <div
              className={`rounded-md px-2 py-3 z-10 border border-lightSection  ${
                props.offset !== props.limit * pages
                  ? "hover:bg-slate-800/70 hover:cursor-pointer"
                  : "text-gray-500"
              }`}
              onClick={() =>
                props.offset !== props.limit * pages &&
                props.setOffset(props.limit * pages)
              }
            >
              <CgChevronDoubleRight className="h-full w-full" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
