import State from "../shared/state";

export enum BracketType {
  Unknown = 0,
  RoundRobin = 1,
  Elimination = 2,
  Swiss = 4,
  DoubleEliminationUpper = 5,
  DoubleEliminationLower = 6,
  DoubleEliminationFinal = 3
}

export interface EditableBracket {
  index: number;
  reference: string;
  name: string | null;
  tournamentId: string;
  type: BracketType;
  state: State;
}

export interface BracketModel extends EditableBracket {
  id: string;
}