import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/vetoes/veto";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";
import { VetoType } from "../../state/vetoes/vetoes";

export const VetoDetails = () => {
  let { id } = useParams();

  const veto = useStore((state) => state.veto);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Veto Details"
        keys={["Id", "Index", "Action", "Action On", "Team Name", "Team Reference", "Series Id"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{veto.id}</span>
        <span>{veto.index}</span>
        <span>{VetoType[veto.action]}</span>
        <span>{veto.actionOnName}</span>
        <span>{veto.actionByTeamName}</span>
        <span>{veto.actionByTeamReference}</span>
        <span>{veto.seriesId}</span>
      </Details>
    </main>
  );
};

export default VetoDetails;