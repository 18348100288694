import create from "zustand";
import axios from "axios";
import SearchResult from "../shared/searchResult";
import RequestState from "../shared/requestState";
import SearchRequest from "../shared/searchRequest";
import { BracketModel } from "./brackets";
import config from "../../config/config";

export interface BracketsSearchState {
  result: SearchResult<BracketModel[]>;
  requestState: RequestState;
  search: (request: SearchRequest) => void;
  deleteState: RequestState;
  delete: (id: string) => void;
  reset: () => void;
}

const useBracketsSearchStore = create<BracketsSearchState>()((set) => ({
  result: {
    total: 0,
    result: []
  },
  requestState: RequestState.NotStarted,
  search: async (request: SearchRequest) => {
    set(() => ({ requestState: RequestState.Loading }));
    try {
      const response = await axios.post<SearchResult<BracketModel[]>>(
        `${config.apiUrl}/brackets/search`,
        request,
        { withCredentials: true }
      );
      set(() => ({
        result: response.data,
        requestState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ requestState: RequestState.Error }));
    }
  },
  deleteState: RequestState.NotStarted,
  delete: async (id: string) => {
    set(() => ({ deleteState: RequestState.Loading }));
    try {
      const response = await axios.delete<BracketModel>(
        `${config.apiUrl}/brackets/${id}`,
        { withCredentials: true }
      );
      const deleted = response.data as BracketModel;
      set((state) => ({
        result: { ...state.result, result: state.result.result.filter(x => x.id !== deleted.id) },
        deleteBannableState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ deleteState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      deleteState: RequestState.NotStarted
    }));
  }
}));

export default useBracketsSearchStore;
