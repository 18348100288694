import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { BanModel, EditableBan } from "./bannables";
import config from "../../config/config";

export interface MatchState {
  bannable: BanModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (bannable: EditableBan) => void;
  editState: RequestState;
  edit: (id: string, bannable: EditableBan) => void;
  reset: () => void;
}

const useBannableStore = create<MatchState>()((set) => ({
  bannable: {} as BanModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<BanModel>(
        `${config.apiUrl}/bannables/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        bannable: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (bannable: EditableBan) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<EditableBan>(
        `${config.apiUrl}/bannables`,
        bannable,
        { withCredentials: true }
      );
      set(() => ({
        bannable: response.data as BanModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, bannable: EditableBan) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<EditableBan>(
        `${config.apiUrl}/bannables/${id}`,
        bannable,
        { withCredentials: true }
      );
      set(() => ({
        bannable: response.data as BanModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useBannableStore;
