import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/series/series";
import RequestState from "../../state/shared/requestState";
import State from "../../state/shared/state";
import Details from "../shared/details";

export const SeriesDetails = () => {
  let { id } = useParams();

  const series = useStore((state) => state.series);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Series Details"
        keys={["Id", "Reference", "Index", "Stage Index", "Best Of", "State", "Start Time", "Bracket Id", "Name"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{series.id}</span>
        <span>{series.reference}</span>
        <span>{series.index}</span>
        <span>{series.stageIndex}</span>
        <span>{series.bestOf}</span>
        <span>{State[series.state]}</span>
        <span>{series.startTime}</span>
        <span>{series.bracketId}</span>
        <span>{series.name}</span>
      </Details>
    </main>
  );
};

export default SeriesDetails;