import React from "react";
import { MdDashboard, MdOutlineScore, MdScore, MdSportsScore } from "react-icons/md";
import { FaBan, FaGamepad, FaMap, FaServer, FaTree, FaTrophy, FaTwitch } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { BsPeople } from "react-icons/bs";
import { GoJersey } from "react-icons/go";
import { GiFamilyTree, GiVerticalBanner } from "react-icons/gi";
import { GrScorecard, GrTree } from "react-icons/gr";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";

export const Sidebar = () => {
  return (
    <aside className="w-64 h-screen" aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 rounded h-full bg-darkSection">
        <ul className="space-y-3">
          <li>
            <NavLink to="/" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <MdDashboard className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <div className="text-white font-extralight border-b border-lightSection">
              RAF-INTERNAL
            </div>
          </li>
          <li>
            <NavLink to="/teams" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <GiVerticalBanner className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Teams</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/players" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <GoJersey className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Players</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/socials" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <BsPeople className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Socials</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/maps" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaMap className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Maps</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/bannables" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaBan className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Bannables</span>
            </NavLink>
          </li>
          <li>
            <div className="text-white font-extralight border-b border-lightSection">
              RAF-MATCHES
            </div>
          </li>
          <li>
            <NavLink to="/organizers" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaServer className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Organizers</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/tournaments" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaTrophy className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Tournaments</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/brackets" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <GiFamilyTree className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Brackets</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/series/search" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <RiCheckboxMultipleBlankFill className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Series</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/matches" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaGamepad className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Matches</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/seriesteams" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <GiVerticalBanner className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Teams</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/matchplayers" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <GoJersey className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Players</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/results" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <MdOutlineScore className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Results</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/vetoes" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaBan className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Vetoes</span>
            </NavLink>
          </li>
          <li>
            <div className="text-white font-extralight border-b border-lightSection">
              RAF-STREAMS
            </div>
          </li>
          <li>
            <NavLink to="/streamers" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaTwitch className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Streamers</span>
            </NavLink>
          </li>
          <li>
            <div className="text-white font-extralight border-b border-lightSection">
              RAF-FANTASY
            </div>
          </li>
          <li>
            <NavLink to="/fantasy" className="flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-lightSection">
              <FaTrophy className="w-6 h-6 transition duration-75 text-gray-400 group-hover:text-white" fill="currentColor" />
              <span className="ml-3">Fantasy</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar;