import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { TeamModel, EditableTeam } from "./teams";
import config from "../../config/config";

export interface TeamState {
  team: TeamModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (team: EditableTeam) => void;
  editState: RequestState;
  edit: (id: string, team: EditableTeam) => void;
  reset: () => void;
}

const useTeamStore = create<TeamState>()((set) => ({
  team: {} as TeamModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<TeamModel>(
        `${config.apiUrl}/seriesteams/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (team: EditableTeam) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<TeamModel>(
        `${config.apiUrl}/seriesteams`,
        team,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, team: EditableTeam) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<TeamModel>(
        `${config.apiUrl}/seriesteams/${id}`,
        team,
        { withCredentials: true }
      );
      set(() => ({
        team: response.data,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useTeamStore;
