import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { BracketModel, EditableBracket } from "./brackets";
import config from "../../config/config";

export interface BracketState {
  bracket: BracketModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (bracket: EditableBracket) => void;
  editState: RequestState;
  edit: (id: string, bracket: EditableBracket) => void;
  reset: () => void;
}

const useBracketStore = create<BracketState>()((set) => ({
  bracket: {} as BracketModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<BracketModel>(
        `${config.apiUrl}/brackets/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        bracket: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (bracket: EditableBracket) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<BracketModel>(
        `${config.apiUrl}/brackets`,
        bracket,
        { withCredentials: true }
      );
      set(() => ({
        bracket: response.data,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, bracket: EditableBracket) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<BracketModel>(
        `${config.apiUrl}/brackets/${id}`,
        bracket,
        { withCredentials: true }
      );
      set(() => ({
        bracket: response.data,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useBracketStore;
