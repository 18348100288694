import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/results/result";
import { ResultType } from "../../state/results/results";
import RequestState from "../../state/shared/requestState";
import Create from "../shared/create";

export const CreateResult = () => {
  const { id } = useParams();

  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [type, setType] = useState<ResultType>(ResultType.Unknown);
  const [score, setScore] = useState<number>(0);
  const [seriesId, setSeriesId] = useState<string>("");
  const [matchId, setMatchId] = useState<string>("");
  const [seriesTeamId, setSeriesTeamId] = useState<string>(id ?? "");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Result"
        keys={["Result Type", "Score", "Series Id", "Match Id", "Series Team Id"]}
        submit={() => create({ resultState: type, score: score, seriesId: seriesId, matchId: matchId, seriesTeamId: seriesTeamId })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <select
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          value={type}
          onChange={(e) => setType(e.target.value as unknown as ResultType)}>
          <option value={ResultType.Unknown}>Unknown</option>
          <option value={ResultType.Undecided}>Undecided</option>
          <option value={ResultType.Winner}>Winner</option>
          <option value={ResultType.Loser}>Loser</option>
          <option value={ResultType.Draw}>Draw</option>
        </select>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="number"
          value={score}
          onChange={(e) => setScore(Number(e.target.value))} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesId ?? ""}
          onChange={(e) => setSeriesId(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={matchId ?? ""}
          onChange={(e) => setMatchId(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesTeamId ?? ""}
          onChange={(e) => setSeriesTeamId(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateResult;