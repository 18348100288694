import create from "zustand";
import axios from "axios";
import RequestState from "../shared/requestState";
import { EditableTournament, TournamentModel } from "./tournaments";
import config from "../../config/config";

export interface TournamentState {
  tournament: TournamentModel;
  fetchState: RequestState;
  fetch: (id: string) => void;
  createState: RequestState;
  create: (tournament: EditableTournament) => void;
  editState: RequestState;
  edit: (id: string, tournament: EditableTournament) => void;
  reset: () => void;
}

const useTournamentStore = create<TournamentState>()((set) => ({
  tournament: {} as TournamentModel,
  fetchState: RequestState.NotStarted,
  fetch: async (id: string) => {
    set(() => ({ fetchState: RequestState.Loading }));
    try {
      const response = await axios.get<TournamentModel>(
        `${config.apiUrl}/tournaments/${id}`,
        { withCredentials: true }
      );
      set(() => ({
        tournament: response.data,
        fetchState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ fetchState: RequestState.Error }));
    }
  },
  createState: RequestState.NotStarted,
  create: async (tournament: EditableTournament) => {
    set(() => ({ createState: RequestState.Loading }));
    try {
      const response = await axios.post<TournamentModel>(
        `${config.apiUrl}/tournaments`,
        tournament,
        { withCredentials: true }
      );
      set(() => ({
        tournament: response.data as TournamentModel,
        createState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ createState: RequestState.Error }));
    }
  },
  editState: RequestState.NotStarted,
  edit: async (id: string, tournament: EditableTournament) => {
    set(() => ({ editState: RequestState.Loading }));
    try {
      const response = await axios.put<TournamentModel>(
        `${config.apiUrl}/tournaments/${id}`,
        tournament,
        { withCredentials: true }
      );
      set(() => ({
        tournament: response.data as TournamentModel,
        editState: RequestState.Complete,
      }));
    } catch (err) {
      set(() => ({ editState: RequestState.Error }));
    }
  },
  reset: () => {
    set(() => ({
      fetchState: RequestState.NotStarted,
      createState: RequestState.NotStarted,
      editState: RequestState.NotStarted
    }));
  }
}));

export default useTournamentStore;
