import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/series_teams/team";
import RequestState from "../../state/shared/requestState";
import Create from "../shared/create";

export const CreateSeriesTeam = () => {
  const { id } = useParams();

  const createState = useStore((state) => state.createState);
  const create = useStore((state) => state.create);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [reference, setReference] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [tag, setTag] = useState<string | null>(null);
  const [seriesId, setSeriesId] = useState<string>(id ?? "");

  return (
    <main className="flex flex-col w-full p-5">
      <Create
        header="Create Team"
        keys={["Reference", "Name", "Tag", "Series Id"]}
        submit={() => create({ reference: reference, name: name, tag: tag, seriesId: seriesId })}
        isLoading={createState === RequestState.Loading}
        isError={createState === RequestState.Error}
        isSuccess={createState === RequestState.Complete}>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={tag ?? ""}
          onChange={(e) => setTag(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={seriesId ?? ""}
          onChange={(e) => setSeriesId(e.target.value)} />
      </Create>
    </main>
  );
};

export default CreateSeriesTeam;