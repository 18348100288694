export enum ResultType {
  Unknown = 0,
  Undecided = 1,
  Winner = 2,
  Loser = 3,
  Draw = 4
}

export interface EditableResult {
  resultState: ResultType;
  score: number;
  seriesId: string | null;
  matchId: string | null;
  seriesTeamId: string;
}

export interface ResultModel extends EditableResult {
  id: string;
}