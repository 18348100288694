import React, { useEffect } from "react";
import useStore from "../../state/results/search";
import RequestState from "../../state/shared/requestState";
import { BsPencilFill } from "react-icons/bs";
import { NavLink, useParams } from "react-router-dom";
import SearchableTable from "../shared/searchableTable";
import SearchableTableResult from "../shared/searchableTableResult";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaPlus, FaTrash } from "react-icons/fa";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";
import { ResultType } from "../../state/results/results";

export const Results = () => {
  const { query } = useParams();

  const result = useStore((state) => state.result);
  const request = useStore((state) => state.requestState);
  const search = useStore((state) => state.search);
  const deleteRequest = useStore((state) => state.deleteState);
  const deleteResult = useStore((state) => state.delete);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <main className="flex flex-col h-full w-full p-5 gap-5">
      <NavLink to={"/result/new"}>
        <div className="flex space-x-3 p-3 place-items-center rounded-md h-full w-1/12 hover:bg-slate-800/70">
          <FaPlus className="text-white" />
          <span className="text-lg text-white">New</span>
        </div>
      </NavLink>

      {deleteRequest === RequestState.Complete && <span className="text-green-400 text-lg">Successfully deleted the data</span>}
      {deleteRequest === RequestState.Error && <span className="text-red-400 text-lg">Failed to delete the data</span>}
      <SearchableTable
        headers={[
          { text: "Id" },
          { text: "Type", selector: "state" },
          { text: "Score" },
          { text: "Series Id" },
          { text: "Match Id" },
          { text: "Series Team Id" },
          { text: "Actions" }]}
        total={result.total}
        search={search}
        query={query}
        isLoading={request === RequestState.Loading}
        isError={request === RequestState.Error}>
        {result.result.map((r, index) =>
          <SearchableTableResult key={index}>
            <span>{r.id}</span>
            <span>{ResultType[r.resultState]}</span>
            <span>{r.score}</span>
            <span>{r.seriesId}</span>
            <span>{r.matchId}</span>
            <span>{r.seriesTeamId}</span>
            <div className="flex justify-center gap-4">
              {r.seriesId &&
                <NavLink to={"/series/" + r.seriesId}>
                  <RiCheckboxMultipleBlankFill />
                </NavLink>}
              {r.matchId &&
                <NavLink to={"/match/" + r.matchId}>
                  <RiCheckboxMultipleBlankFill />
                </NavLink>}
              <NavLink to={`/result/${r.id}`}>
                <GiHamburgerMenu />
              </NavLink>
              <NavLink to={`/result/${r.id}/edit`}>
                <BsPencilFill />
              </NavLink>
              <button onClick={() => deleteResult(r.id)}>
                <FaTrash />
              </button>
            </div>
          </SearchableTableResult>
        )}
      </SearchableTable>
    </main>
  );
};

export default Results;