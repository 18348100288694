import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/organizers/organizer";
import RequestState from "../../state/shared/requestState";
import Details from "../shared/details";

export const OrganizerDetails = () => {
  let { id } = useParams();

  const organizer = useStore((state) => state.organizer);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  return (
    <main className="flex flex-col h-full p-5">
      <Details
        header="Organizer Details"
        keys={["Id", "Name", "Tag", "Image"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{organizer.id}</span>
        <span>{organizer.name}</span>
        <span>{organizer.tag}</span>
        <div>
          <img alt="Organizer" src={organizer.logo} />
          <a href={organizer.logo}>
            {organizer.logo}
          </a>
        </div>
      </Details>
    </main>
  );
};

export default OrganizerDetails;