import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import useStore from "../../state/fantasy/fantasy";
import usePlayerStore from "../../state/fantasy/players";
import useSeriesStore from "../../state/series/search";
import useRoundStore from "../../state/fantasy/rounds";
import RequestState from "../../state/shared/requestState";
import { Condition, Operation } from "../../state/shared/searchRequest";
import Create from "../shared/create";
import Details from "../shared/details";
import SearchableDropdown from "../shared/searchableDropdown";
import SearchableTable from "../shared/searchableTable";
import SearchableTableResult from "../shared/searchableTableResult";
import { FantasyRoles } from "../../state/fantasy/models";

export const FantasyDetails = () => {
  let { id } = useParams();

  const fantasy = useStore((state) => state.fantasy);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);

  const playerSearchState = usePlayerStore((state) => state.searchState);
  const playerResult = usePlayerStore((state) => state.result);
  const searchPlayers = usePlayerStore((state) => state.search);

  const playerCreateState = usePlayerStore((state) => state.createState);
  const createPlayer = usePlayerStore((state) => state.create);

  const playerDeleteState = usePlayerStore((state) => state.deleteState);
  const deletePlayer = usePlayerStore((state) => state.delete);

  const roundSearchState = useRoundStore((state) => state.searchState);
  const roundResult = useRoundStore((state) => state.result);
  const searchRounds = useRoundStore((state) => state.search);

  const roundCreateState = useRoundStore((state) => state.createState);
  const createRound = useRoundStore((state) => state.create);

  const roundDeleteState = useRoundStore((state) => state.deleteState);
  const deleteRound = useRoundStore((state) => state.delete);
  const reset = useRoundStore((state) => state.reset);

  const addSeriesState = useRoundStore((state) => state.addSeriesState);
  const addSeries = useRoundStore((state) => state.addSeries);

  const removeSeriesState = useRoundStore((state) => state.addSeriesState);
  const removeSeries = useRoundStore((state) => state.removeSeries);

  const seriesSearchState = useSeriesStore((state) => state.requestState);
  const seriesResult = useSeriesStore((state) => state.result);
  const searchSeries = useSeriesStore((state) => state.search);

  const [name, setName] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [role, setRole] = useState<FantasyRoles>(FantasyRoles.Unknown);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [teamName, setTeamName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);

  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    // Runs after the first render() lifecycle
    if (id !== undefined) {
      fetch(id);
    }
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <main className="flex flex-col w-full h-full p-5">
      <Details
        header="Fantasy Details"
        keys={["Id", "Tournament Id"]}
        isLoading={fetchState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error}>
        <span>{fantasy.id}</span>
        <span>{fantasy.tournamentId}</span>
      </Details>

      <span className="py-3 px-6 text-start text-white text-lg">Players</span>
      <div className="flex">
        <div className="w-full">
          {playerDeleteState === RequestState.Complete && <span className="text-green-400 text-lg">Successfully deleted the data</span>}
          {playerDeleteState === RequestState.Error && <span className="text-red-400 text-lg">Failed to delete the data</span>}
          <SearchableTable
            headers={[{ text: "Name" }, { text: "Reference" }, { text: "Price" }, { text: "Actions" }]}
            total={playerResult.total}
            search={searchPlayers}
            isLoading={playerSearchState === RequestState.Loading}
            isError={playerSearchState === RequestState.Error}
            filters={[{ operation: Operation.And, field: "FantasyId", terms: [{ operation: Operation.And, condition: Condition.Equals, value: fantasy.id }] }]}>
            {playerResult.result.map((r, index) =>
              <SearchableTableResult key={index}>
                <span>{r.name}</span>
                <span>{r.reference}</span>
                <span>{r.price}</span>
                <div className="flex justify-center gap-4">
                  <button onClick={() => deletePlayer(r.id)}>
                    <FaTrash />
                  </button>
                </div>
              </SearchableTableResult>
            )}
          </SearchableTable>
        </div>

        <div className="w-full h-full">
          <Create
            header="Create Player"
            keys={["Name", "Reference", "Role", "Team Id", "Team Name", "Price"]}
            submit={() => createPlayer({ name: name, reference: reference, role: role, teamId: teamId, teamName: teamName, price: price, fantasyId: fantasy.id })}
            isLoading={playerCreateState === RequestState.Loading}
            isError={playerCreateState === RequestState.Error}
            isSuccess={playerCreateState === RequestState.Complete}>
            <input
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              type="text"
              value={name ?? ""}
              onChange={(e) => setName(e.target.value)} />
            <input
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              type="text"
              value={reference ?? ""}
              onChange={(e) => setReference(e.target.value)} />
            <select
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              value={role}
              onChange={(e) => setRole(e.target.value as unknown as FantasyRoles)}>
              <option value={FantasyRoles.Unknown}>Unknown</option>
              <option value={FantasyRoles.Rifler}>Rifler</option>
              <option value={FantasyRoles.Sniper}>Sniper</option>
              <option value={FantasyRoles.Leader}>Leader</option>
              <option value={FantasyRoles.Support}>Support</option>
            </select>
            <input
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              type="text"
              value={teamId ?? ""}
              onChange={(e) => setTeamId(e.target.value)} />
            <input
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              type="text"
              value={teamName ?? ""}
              onChange={(e) => setTeamName(e.target.value)} />
            <input
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              type="number"
              value={price ?? ""}
              onChange={(e) => setPrice(Number(e.target.value))} />
          </Create>
        </div>
      </div>

      <span className="py-3 px-6 text-start text-white text-lg">Rounds</span>
      <div className="flex">
        <div className="w-full">
          {roundDeleteState === RequestState.Complete && <span className="text-green-400 text-lg">Successfully deleted the data</span>}
          {roundDeleteState === RequestState.Error && <span className="text-red-400 text-lg">Failed to delete the data</span>}
          <SearchableTable
            headers={[{ text: "Index" }, { text: "Add Series" }, { text: "Remove Series" }, { text: "Actions" }]}
            total={roundResult.total}
            search={searchRounds}
            isLoading={roundSearchState === RequestState.Loading}
            isError={roundSearchState === RequestState.Error}
            filters={[{ operation: Operation.And, field: "FantasyId", terms: [{ operation: Operation.And, condition: Condition.Equals, value: fantasy.id }] }]}>
            {roundResult.result.map((r, index) =>
              <SearchableTableResult key={index}>
                <span>{r.index}</span>
                <div>
                  <SearchableDropdown
                    total={seriesResult.total}
                    headers={["Start Time"]}
                    selected={null}
                    search={(request) => searchSeries({ ...request, filters: [{ operation: Operation.And, field: "TournamentId", terms: [{ operation: Operation.And, condition: Condition.Equals, value: fantasy.tournamentId }] }] })}
                    isLoading={seriesSearchState === RequestState.Loading}
                    isError={seriesSearchState === RequestState.Error}>
                    {seriesResult.result.map((series, index) =>
                      <button
                        key={index}
                        onClick={() => addSeries({ roundId: r.id, seriesId: series.series.id })}
                        className="flex p-1 text-start gap-x-4 items-center hover:bg-slate-800/70 hover:cursor-pointer">
                        {series.series.startTime}
                      </button>)}
                  </SearchableDropdown>
                </div>
                <div>
                  <SearchableDropdown
                    total={seriesResult.total}
                    headers={["Start Time"]}
                    selected={null}
                    search={(request) => { }}
                    isLoading={seriesSearchState === RequestState.Loading}
                    isError={seriesSearchState === RequestState.Error}>
                    {r.series.map((series, index) =>
                      <button
                        key={index}
                        onClick={() => removeSeries(series.id)}
                        className="flex p-1 text-start gap-x-4 items-center hover:bg-slate-800/70 hover:cursor-pointer">
                        {series.seriesId}
                      </button>)}
                  </SearchableDropdown>
                </div>
                <div className="flex justify-center gap-4">
                  <button onClick={() => deleteRound(r.id)}>
                    <FaTrash />
                  </button>
                </div>
              </SearchableTableResult>
            )}
          </SearchableTable>
        </div>

        <div className="w-full h-full">
          <Create
            header="Create Round"
            keys={["Index"]}
            submit={() => createRound({ index: index, fantasyId: fantasy.id })}
            isLoading={roundCreateState === RequestState.Loading}
            isError={roundCreateState === RequestState.Error}
            isSuccess={roundCreateState === RequestState.Complete}>
            <input
              className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
              type="number"
              value={index ?? ""}
              onChange={(e) => setIndex(Number(e.target.value))} />
            <div></div>
          </Create>
        </div>
      </div>
    </main>
  );
};

export default FantasyDetails;