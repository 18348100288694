import React, { useEffect } from "react";
import useStore from "../../state/matches/search";
import RequestState from "../../state/shared/requestState";
import { BsPencilFill } from "react-icons/bs";
import { NavLink, useParams } from "react-router-dom";
import SearchableTable from "../shared/searchableTable";
import SearchableTableResult from "../shared/searchableTableResult";
import { GiCheckMark, GiHamburgerMenu } from "react-icons/gi";
import { FaPlus, FaTrash } from "react-icons/fa";
import State from "../../state/shared/state";
import GameType from "../../state/shared/gameType";
import { GoJersey } from "react-icons/go";

export const Matches = () => {
  const { query } = useParams();

  const result = useStore((state) => state.result);
  const request = useStore((state) => state.requestState);
  const search = useStore((state) => state.search);
  const deleteRequest = useStore((state) => state.deleteState);
  const deleteMatch = useStore((state) => state.delete);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <main className="flex flex-col h-full w-full p-5 gap-5">
      <NavLink to={"/matches/new"}>
        <div className="flex space-x-3 p-3 place-items-center rounded-md h-full w-1/12 hover:bg-slate-800/70">
          <FaPlus className="text-white" />
          <span className="text-lg text-white">New</span>
        </div>
      </NavLink>

      {deleteRequest === RequestState.Complete && <span className="text-green-400 text-lg">Successfully deleted the data</span>}
      {deleteRequest === RequestState.Error && <span className="text-red-400 text-lg">Failed to delete the data</span>}
      <SearchableTable
        headers={[
          { text: "Id" },
          { text: "Index", selector: "index" },
          { text: "Best Of" },
          { text: "State", selector: "state" },
          { text: "Game", selector: "game" },
          { text: "Teams" },
          { text: "Players" },
          { text: "Actions" }]}
        total={result.total}
        search={search}
        query={query}
        isLoading={request === RequestState.Loading}
        isError={request === RequestState.Error}>
        {result.result.map((r, index) =>
          <SearchableTableResult key={index}>
            <span>{r.id}</span>
            <span>{r.index}</span>
            <span>{r.bestOf}</span>
            <span>{State[r.state]}</span>
            <span>{GameType[r.game]}</span>
            <span>{r.hasTeams && <GiCheckMark className="mx-auto" />}</span>
            <span>{r.hasPlayers && <GiCheckMark className="mx-auto" />}</span>
            <div className="flex justify-center gap-4">
              <NavLink to={"/matchplayer/new/" + r.id}>
                <FaPlus />
              </NavLink>
              <NavLink to={"/matchplayers/" + r.id}>
                <GoJersey />
              </NavLink>
              <NavLink to={`/match/${r.id}`}>
                <GiHamburgerMenu />
              </NavLink>
              <NavLink to={`/match/${r.id}/edit`}>
                <BsPencilFill />
              </NavLink>
              <button onClick={() => deleteMatch(r.id)}>
                <FaTrash />
              </button>
            </div>
          </SearchableTableResult>
        )}
      </SearchableTable>
    </main>
  );
};

export default Matches;