import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/players/player";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";
import { FaTrash } from "react-icons/fa";
import SocialMappingList from "../shared/socialMappingList";
import ReferenceMappingList from "../shared/referenceMappingList";

export const EditPlayer = () => {
  let { id } = useParams();

  const player = useStore((state) => state.player);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  const addSocialState = useStore((state) => state.addSocialState);
  const addSocial = useStore((state) => state.addSocial);
  const deleteSocialState = useStore((state) => state.deleteSocialState);
  const deleteSocial = useStore((state) => state.deleteSocial);

  const addReferenceState = useStore((state) => state.addReferenceState);
  const addReference = useStore((state) => state.addReference);
  const deleteReferenceState = useStore((state) => state.deleteReferenceState);
  const deleteReference = useStore((state) => state.deleteReference);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [alias, setAlias] = useState<string>(player.alias);
  const [name, setName] = useState<string>(player.name);
  const [birthday, setBirthday] = useState<string>(player.birthday);
  const [image, setImage] = useState<string>(player.image);

  useEffect(() => {
    setAlias(player.alias);
    setName(player.name);
    setBirthday(player.birthday);
    setImage(player.image);
  }, [player]);

  if (id === undefined || fetchState === RequestState.Error) {
    return (
      <>
        <span className="text-center text-red-300">Failed to fetch player</span>
      </>
    )
  }

  if (fetchState !== RequestState.Complete) {
    return (
      <>
        <span className="text-center text-white">Loading...</span>
      </>
    )
  }

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Player"
        keys={["Id", "Alias", "Name", "Birthday", "Image"]}
        save={() => edit(player.id, { alias: alias, name: name, birthday: birthday, image: image })}
        isLoading={editState === RequestState.Loading}
        isError={editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{player.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={alias ?? ""}
          onChange={(e) => setAlias(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={birthday ?? ""}
          onChange={(e) => setBirthday(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={image ?? ""}
          onChange={(e) => setImage(e.target.value)} />
      </Edit>

      <SocialMappingList
        header={"Socials"}
        add={(socialId, link) => addSocial(player.id, socialId, { link: link })}
        isLoading={addSocialState === RequestState.Loading || deleteSocialState === RequestState.Loading}
        isError={addSocialState === RequestState.Error || deleteSocialState === RequestState.Error}
        isSuccess={addSocialState === RequestState.Complete || deleteSocialState === RequestState.Complete}>
        {player.socials.map((mapping, index) =>
          <div key={index} className="flex px-6 w-full font-light justify-between items-center">
            <img alt="Social" width={52} src={mapping.social.image} />
            <span className="p-1 w-11/12">{mapping.link}</span>
            <div
              onClick={() => deleteSocial(id!, mapping.id)}
              className="py-1 rounded-md bg-darkBg hover:bg-slate-800/70 hover:cursor-pointer">
              <FaTrash className="mx-3 text-red-500 h-full " />
            </div>
          </div>)}
      </SocialMappingList>

      <ReferenceMappingList
        header={"References"}
        add={(reference) => addReference(player.id, { reference: reference })}
        isLoading={addReferenceState === RequestState.Loading || deleteReferenceState === RequestState.Loading}
        isError={addReferenceState === RequestState.Error || deleteReferenceState === RequestState.Error}
        isSuccess={addReferenceState === RequestState.Complete || deleteReferenceState === RequestState.Complete}>
        {player.references.map((mapping, index) =>
          <div key={index} className="flex px-6 w-full font-light text-lg justify-between items-center h-12">
            <span className="p-1 w-11/12">{mapping.value}</span>
            <div
              onClick={() => deleteReference(id!, mapping.id)}
              className="py-1 rounded-md bg-darkBg hover:bg-slate-800/70 hover:cursor-pointer">
              <FaTrash className="mx-3 text-red-500 h-full " />
            </div>
          </div>)}
      </ReferenceMappingList>
    </main>
  );
};

export default EditPlayer;