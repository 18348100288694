import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/match_players/player";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditMatchPlayer = () => {
  let { id } = useParams();

  const player = useStore((state) => state.player);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [reference, setReference] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [teamReference, setTeamReference] = useState<string>("");
  const [matchId, setMatchId] = useState<string>("");

  useEffect(() => {
    setReference(player.reference);
    setName(player.name);
    setTeamReference(player.teamReference);
    setMatchId(player.matchId);
  }, [player]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Player"
        keys={["Id", "Reference", "Name", "Team Reference", "Match Id"]}
        save={() => edit(player.id, { reference: reference, name: name, teamReference: teamReference, matchId: matchId })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{player.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={teamReference ?? ""}
          onChange={(e) => setTeamReference(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={matchId ?? ""}
          onChange={(e) => setMatchId(e.target.value)} />
      </Edit>
    </main>
  );
};

export default EditMatchPlayer;