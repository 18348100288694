import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../state/streamers/streamer";
import RequestState from "../../state/shared/requestState";
import Edit from "../shared/edit";

export const EditStreamer = () => {
  let { id } = useParams();

  const streamer = useStore((state) => state.streamer);
  const fetchState = useStore((state) => state.fetchState);
  const fetch = useStore((state) => state.fetch);
  const editState = useStore((state) => state.editState);
  const edit = useStore((state) => state.edit);
  const reset = useStore((state) => state.reset);

  useEffect(() => {
    if (id !== undefined) fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const [name, setName] = useState<string>(streamer.name);
  const [link, setLink] = useState<string>(streamer.link);
  const [twitchId, setTwitchId] = useState<string>(streamer.twitchId);

  useEffect(() => {
    setName(streamer.name);
    setLink(streamer.link);
    setTwitchId(streamer.twitchId);
  }, [streamer]);

  return (
    <main className="flex flex-col w-full p-5">
      <Edit
        header="Edit Streamer"
        keys={["Id", "Name", "Link", "Twitch Id", "Followers", "Image"]}
        save={() => edit(streamer.id, { name: name, link: link, twitchId: twitchId })}
        isLoading={fetchState === RequestState.Loading || editState === RequestState.Loading}
        isError={id === undefined || fetchState === RequestState.Error || editState === RequestState.Error}
        isSuccess={editState === RequestState.Complete}>
        <span>{streamer.id}</span>
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={link ?? ""}
          onChange={(e) => setLink(e.target.value)} />
        <input
          className="drop-shadow-2xl px-2 text-white font-extralight text-md rounded-l-md bg-darkSection w-full h-8"
          type="text"
          value={twitchId ?? ""}
          onChange={(e) => setTwitchId(e.target.value)} />
        <span>{streamer.followers}</span>
        <span>
          <a href={streamer.image}>
            {streamer.image}
          </a>
        </span>
      </Edit>
    </main>
  );
};

export default EditStreamer;