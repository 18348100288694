import React, { ReactNode, useEffect, useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import SearchRequest from "../../state/shared/searchRequest";
import Pagination from "./pagination";
import SearchField from "./SearchField";

export interface SearchableDropdownProps {
  total: number;
  headers: string[];
  selected: string | null;
  search: (request: SearchRequest) => void;
  isLoading?: boolean;
  isError?: boolean;
  children: ReactNode[];
}

export const SearchableDropdown = (props: SearchableDropdownProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [request, setRequest] = useState<SearchRequest>({
    query: "",
    offset: 0,
    limit: 10,
    ordering: [],
    filters: []
  });

  const { search } = props;
  useEffect(() => {
    search(request);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  if (props.isLoading) {
    return (
      <>
        <span className="text-center text-white">Loading...</span>
      </>
    )
  }

  if (props.isError) {
    return (
      <>
        <span className="text-center text-red-300">Failed to load search</span>
      </>
    )
  }

  return (
    <div className="flex flex-col h-full overflow-visible">
      <button
        disabled={props.isLoading}
        onClick={() => setShow(!show)}
        className="flex p-3 rounded-md h-full items-center bg-black bg-opacity-20 hover:bg-slate-800/70 hover:cursor-pointer">
        <span className="text-md text-white truncate w-36">{props.selected ? props.selected : "Select"}</span>
        {show
          ? <AiFillCaretRight width={100} height={100} className="mr-2 text-white h-full" />
          : <AiFillCaretDown width={100} height={100} className="mr-2 text-white h-full" />}
      </button>
      <div style={{ top: "90%", left: "1%" }} className={`${show ? "absolute border-lightSection border-2 border-rounded bg-darkSection z-10" : "hidden"}`}>
        <SearchField
          query={request.query}
          setQuery={(query: string) => setRequest({ ...request, query: query })} />
        <div
          className="flex flex-col p-3 gap-y-2 divide-gray-400 divide-opacity-5"
          onClick={() => setShow(false)}>
          {props.children}
        </div>
        <div className="px-2">
          <Pagination
            total={props.total}
            offset={request.offset}
            limit={request.limit}
            setOffset={(offset: number) =>
              setRequest({ ...request, offset: offset })
            }
            setLimit={(limit: number) =>
              setRequest({ ...request, limit: limit, offset: 0 })
            } />
        </div>
      </div>
    </div>
  );
}

export default SearchableDropdown;