export enum VetoType {
  Unknown = 0,
  Ban = 1,
  Pick = 2,
  Leftover = 3
}

export interface EditableVeto {
  index: number;
  action: VetoType;
  actionByTeamName: string | null;
  actionByTeamReference: string | null;
  actionOnName: string;
  seriesId: string;
}

export interface VetoModel extends EditableVeto {
  id: string;
}